import React, { memo } from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";
import { LoadingStyled } from "./styled/loading.styled";

//<Loading size="" innerPageActive={true}/>

// size = middle / small /large
// innerPageActive = sayfa içerisinde arka plana pusluk yapıyor

const Loading = (props) => {
  const { size, innerPageActive } = props;
  return (
    <LoadingStyled
      className={"loading " + (innerPageActive ? "loading--innerPage" : "")}
    >
      <Spin size={size || "large"} />
    </LoadingStyled>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
  innerPageActive: PropTypes.bool,
};

export default memo(Loading);
