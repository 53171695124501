/* eslint-disable no-undef */
import { useState } from "react";

const useLocalStorage = (key, initialValue) => {
  const [state, setState] = useState(() => {
    // Initialize the state
    try {
      if (typeof window !== "undefined") {
        const value = window.localStorage.getItem(key);
        // Check if the local storage already has any values,
        // otherwise initialize it with the passed initialValue
        return value ? JSON.parse(value) : initialValue;
      }
      return initialValue; // If not in browser, return initial value
    } catch{
      return initialValue; // Return initial value on error
    }
  });

  const setValue = (value) => {
    try {
      if (typeof window !== "undefined") {
        // If the passed value is a callback function,
        // then call it with the existing state.
        const valueToStore = value instanceof Function ? value(state) : value;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        setState(valueToStore); // Use valueToStore instead of value
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [state, setValue];
};

export default useLocalStorage;
