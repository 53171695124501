import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialProductCategoriesState } from "../initialState";

const hydrate = createAction(HYDRATE);

const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState: initialProductCategoriesState,
  reducers: {
    setItemList: (state, payload) => {
      state.data = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
    setTitleAndSlug: (state, { payload }) => {
      state.productTitle = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.productCategories,
      };
    });
  },
});

const productCategoriesData = (state) => state.productCategories;
const selectItemList = (state) => state.productCategories.data;
const selectTitleAndSlug = (state) => state.productCategories.productTitle;

export const { setItemList, setTitleAndSlug } = productCategoriesSlice.actions;
export { productCategoriesData, selectItemList, selectTitleAndSlug };

export default productCategoriesSlice.reducer;
