import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormSubmitButtonStyled = styled.div`
  position: relative;

  &.formSubmitButton--fullWidth {
    width: 100%;
    button {
      width: 100%;
    }
  }
  /* antd */
  .ant {
    &-form-item {
      margin-bottom: 0;
    }
  }

  /* red */
  .ant-redBtn {
    ${settingsStyled.antBtnGeneralContainer}
    background-color: #ff002b;
    margin: 0px;

    .icon {
      color: #fff;
    }

    & span {
      color: #fff;
      font-size: 16px;
      line-height: 1.5;
    }
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  /* blue */
  .ant-blueBtn {
    ${settingsStyled.antBtnGeneralContainer}
    background: #1C3B84;
    margin: 0px;
    min-height: 80px;
    border-radius: 8px;

    .icon {
      color: #fff;
    }

    & span {
      color: #fff;
      font-size: 20px;
      line-height: 30px;
    }
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  /* red  Small */
  .ant-redBtnSmall {
    ${settingsStyled.antBtnGeneralContainer}
    background-color: #ff002b;
    min-height: 40px;
    & span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
    }
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .ant-greyBtn {
    ${settingsStyled.antBtnGeneralContainer}
    background-color: #f5f8f8;
    min-height: 40px;

    & span {
      color: #a0b4bc;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .formSubmitButton--iconLeft {
    margin-right: 8px;
  }

  .formSubmitButton--iconRight {
    margin-left: 24px;
  }
  .formSubmitButton-iconOut {
    margin-top: -3px;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .ant-whiteBtn {
    ${settingsStyled.antBtnGeneralContainer}
    background-color: #fff;
    border: solid 1px #326b7c;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #00384d;
    }

    &[disabled] {
      background-color: #f5f8f8;

      span {
        font-size: 14px;
        font-weight: 500;
        color: black;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  /* greyBtn */
  .ant-greyBtn {
    ${settingsStyled.antBtnGeneralContainer}
    background-color: #f5f8f8;

    span {
      color: #a0b4bc;
    }
  }
`;
