import { getInitApp } from "../../store/general/initAppSlice";
import { getTranslationResource } from "../../store/general/translationResourceSlice";
import {
  getCookiesServer,
  setCookiesServer,
} from "../utils/cookie/cookiesServer";
import Router from "next/router";
import { getUser } from "../../store/private/userSlice";
import { getSeoTags } from "../../store/general/seoTagsSlice";
import { findData } from "../utils/mixedControl";
import { Constants } from "../utils/constants";
import { getLanguageID } from "../utils/common";
import { getFooterData } from "../../store/private/footerSlice";
import { getHeaderMenu } from "../../store/general/headerMenuSlice";
import CachedData from "../../store/CachedData";

// authority login olmak zorunda ise  true yollanmalı hangi sayfa ise
// userClosePage eğer login ise sayfaya girmesini engelliyor
const Auth = async ({ ctx, store }, authority = false, userClosePage = false) => {
  const { dispatch, getState } = store;
  const { req, res, pathname } = ctx;
  let { initApp, userInformation } = getState();
  let isLoggedIn = initApp?.isLoggedIn || false;

  ctx.userSettings = {};
  const languageID = typeof window !== "undefined"
    ? getLanguageID()
    : getCookiesServer(res, req, "languageID") || Constants.DEFAULT_LANGUAGE_ID;

  ctx.userSettings.languageID = languageID;

  if (userClosePage && isLoggedIn) {
    if (req) {
      res.writeHead(302, { Location: "/" });
      res.end();
    } else {
      Router.push("/");
    }
    return;
  }

  if (initApp.loading) {
    await dispatch(getInitApp(ctx));
    initApp = getState().initApp;
    isLoggedIn = initApp?.isLoggedIn || false;

    ctx.userSettings.token = initApp.token;
    setCookiesServer(res, req, "token", initApp.token);

    await dispatch(getTranslationResource(languageID, ctx));
    CachedData.Translation = cleanData(getState().translation.item || {});

    await dispatch(getHeaderMenu(ctx));
    CachedData.Header = cleanData(getState().headerMenu.item || {});

    await dispatch(getFooterData(ctx));
    CachedData.Footer = cleanData(getState().footer.item.item || {});
  }

  if (isLoggedIn && userInformation?.loading && !initApp.loading) {
    await dispatch(getUser(ctx));
  }

  if (authority && !isLoggedIn) {
    if (req) {
      res.writeHead(302, { Location: "/login" });
      res.end();
    } else {
      Router.push("/login");
    }
    return;
  }

  if (userClosePage && isLoggedIn) {
    if (req) {
      res.writeHead(302, { Location: "/" });
      res.end();
    } else {
      Router.push("/");
    }
    return;
  }

  if (!initApp.loading) {
    let seoTags = getState().seoTags?.items || [];
    const selectSeoTags = findData(seoTags, "url", pathname);

    if (!selectSeoTags) {
      await dispatch(getSeoTags(pathname, ctx));
    }
  }
};

export const cleanData = (data) => {
  if (data === undefined) return null;
  if (Array.isArray(data)) {
    return data.map(cleanData);
  }
  if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = cleanData(data[key]);
      return acc;
    }, {});
  }
  return data;
};

export default Auth;
