import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { initialObjectState } from "../initialState";

const hydrate = createAction(HYDRATE);

const userSlice = createSlice({
  name: "userInformation",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
    userClear: (state) => {
      // state.item = null;
      state.loading = true;
      state.hasErrors = false;
      state.hasMessage = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.userInformation,
      };
    });
  },
});

export const userInformationData = (state) => state.userInformation; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed, userClear } = userSlice.actions; // functions dışarıya aktarılması

export default userSlice.reducer;

export function getUser(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.membership.myProfile({}).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(res.data));
      } else {
        dispatch(failed(res.hasMessage));
      }
    });
  };
}
