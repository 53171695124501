import { memo, useState, useCallback, useEffect } from "react";
import { LayoutMainHeaderStyled } from "../styled/layoutMainHeaderStyled.styled";
import HeaderContent from "../../../../components/Header/headerContent";
import Search from "../../../partnerComponents/search";
import { useRouter } from "next/router";

const LayoutMainHeader = memo(() => {
  const [openSearch, setOpenSearch] = useState(false);
  const router = useRouter();

  const openSearchControl = useCallback(() => {
    setOpenSearch((prev) => !prev);
  }, []);

  useEffect(() => {
    setOpenSearch(false);
  }, [router.pathname]);

  return (
    <LayoutMainHeaderStyled
      className="layoutMainHeader"
      style={{ position: "relative", zIndex: "3" }}
    >
      <div id="navbar" className="layoutMainHeader-container">
        <HeaderContent openSearchControl={openSearchControl} />
      </div>
      <Search
        placeholder={"Ara"}
        allowClear={true}
        iconName="icon-search"
        iconPositon="left"
        menuActive={true}
        backBtnShow={true}
        openSearchControl={openSearchControl}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      />
    </LayoutMainHeaderStyled>
  );
});

export default LayoutMainHeader;
