import React, { memo } from "react";
import Icon from "../icons/icon";
import CustomLink from "../customLink";
import getWordControl from "../../utils/language/getWordControl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SearchResultStyled } from "../../../styled/partialComponents/searchResult.styled";

//<SearchResult
//popularItems={popularItems} //popüler arama listesi
//searchItems={searchItems} // arama sonuçları listesi
//hideTitle={true} // başlık alanını gizle
//showItemsCount={3} // kaç arama sonucu gözüksün
//value=""
//totalDataCount
//    openSearchControl,

///>

const SearchResult = memo(
  ({
    popularItems,
    searchItems,
    hideTitle = false,
    smallCardActive = false,
    showItemsCount,
    value,
    totalDataCount,
    openSearchControl,
  }) => {
    const { GetWord } = getWordControl();


    return searchItems?.item && searchItems?.item?.length > 0 ? (
      <SearchResultStyled
        className={
          "searchResult" + (smallCardActive ? "searchResult--showList" : "")
        }
      >
        {!hideTitle && (
          <div className="searchResult-titleContainer">
            <h6 className="searchResult-titleContainer__titleResult">
              <span>"{value.toLocaleUpperCase()}" </span>
              {GetWord("bestMatches")} ({totalDataCount})
            </h6>
          </div>
        )}
        <ul className="searchResult-items">
          {searchItems?.item
            ?.slice(0, showItemsCount || 9999)
            ?.map((item, i) => (
              <li className="searchResult-itemResult" key={i}>
                <CustomLink
                  href={item?.link}
                  className="searchResult-itemResult__link"
                  onClick={openSearchControl}
                >
                  {item?.imageUrl && (
                    <div className="searchResult-itemResult__link-image">
                      <LazyLoadImage src={item?.imageUrl} alt={item?.title} />
                    </div>
                  )}

                  <div className="searchResult-itemResult__link-info">
                    <div className="searchResult-itemResult__link-info__title hovering">
                      {GetWord(item?.getWordKey)}
                    </div>

                    {item?.subTitle && (
                      <h5
                        className="searchResult-itemResult__link-info__title hovering"
                        dangerouslySetInnerHTML={{
                          __html: item.subTitle,
                        }}
                      ></h5>
                    )}
                    {item?.summary && (
                      <div
                        className="searchResult-itemResult__link-info__summary hovering"
                        dangerouslySetInnerHTML={{
                          __html: item.summary,
                        }}
                      ></div>
                    )}
                    <div
                      className="searchResult-itemResult__link-info__subtitle"
                      dangerouslySetInnerHTML={{
                        __html: item?.desc,
                      }}
                    ></div>
                  </div>
                  <Icon name="icon-chevron-right" />
                </CustomLink>
              </li>
            ))}
        </ul>
      </SearchResultStyled>
    ) : (
      <SearchResultStyled className="searchResult">
        <div className="searchResult-noSearch">{GetWord("NoReturn")}</div>
      </SearchResultStyled>
    );
  }
);

export default SearchResult;
