import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { initialObjectState } from "../initialState";
import { clarificationTextPageModel } from "../../shared/models/mainComponents/clarficationTextPageModel";

const hydrate = createAction(HYDRATE);

const clarificationTextPageSlice = createSlice({
  name: "clarificationText",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.clarificationText,
      };
    });
  },
});

export const clarificationTextData = (state) => state.clarificationText;
export const { setItemList, failed } = clarificationTextPageSlice.actions;

export default clarificationTextPageSlice.reducer;

export function getClarificationTextData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.content
      .getGetPage({
        data: {
          code: "clarificationText",
        },
      })
      .then((res) => {
        const newData = clarificationTextPageModel(
          res,
          ctx.userSettings.languageID
        );

        if (res?.data && !res.hasFailed) {
          dispatch(setItemList(newData));
        } else {
          dispatch(failed(res?.messages?.[0]?.description));
        }
      });
  };
}
