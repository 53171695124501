import { GetI18n } from "../../utils/mixedControl";

export function productVideosPageModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;
  if (data && !requestData.hasFailed) {
    const { productCategoryID, productCategoryMenuItems } = data;
    return {
      name: GetI18n(data, "name", languageID),
      headlines: headlinesModel(data?.headlines, languageID),
      productCategoryID: productCategoryID ? productCategoryID : null,
      productCategoryMenuItems: productCategoryMenuItems ? menuItemsModel(
        productCategoryMenuItems,
        languageID
      ) : null,
      promotionAndFactoryVideoItems: data?.promotionAndFactoryVideoItems ? videoItemsModel(
        data?.promotionAndFactoryVideoItems,
        languageID
      ) : null,
      productAndLineVideoItems:  data?.productAndLineVideoItems ? videoItemsModel(
        data?.productAndLineVideoItems,
        languageID
      ) : null,
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData ? requestData?.messages : [],
    };
  }
}

function headlinesModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        title: GetI18n(item, "title", languageID),
      };
    });
  }
  return newData;
}

function videoItemsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        title: GetI18n(item, "title", languageID),
        filePath: item?.filePath ? item?.filePath : null,
      };
    });
  }
  return newData;
}

function menuItemsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        name: GetI18n(item, "name", languageID),
        productCategoryID: item?.productCategoryID ? item?.productCategoryID : null,
        id: item?.id,
        webImagePath: item?.webImagePath ? item?.webImagePath : null,
        mobileImagePath: item?.mobileImagePath ? item?.mobileImagePath : null,
        url: item?.url ? item?.url : null,
      };
    });
  }
  return newData;
}
