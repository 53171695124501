// genel sayfa içerinde map leme yapılacak veriler için
export const initialListState = {
  loading: true,
  hasErrors: false,
  hasMessage: null,
  items: [],
};

// genel sayfa içerinde map leme yapılacak veriler için
export const initialObjectState = {
  loading: true,
  hasErrors: false,
  hasMessage: null,
  item: {},
  data:{},
  productDetail: {},
  catologId: null || 1,
  productId: null || 1,
  solutionCategoryID: null || 1
};

// selectbox
export const initialSelectState = {
  hasErrors: false,
  hasMessage: null,
  selectList: [],
};

const initialHomePageState = {
  hasErrors: false,
  hasMessage: null,
  loading: true,
  data: [],
  productId: 1,
};
const initialProductCategoriesState = {
  hasErrors: false,
  hasMessage: null,
  loading: true,
  data: [],
  productId: 1,
  productTitle: {
    title: null,
    slug: null,
  },
};

export { initialHomePageState, initialProductCategoriesState };
