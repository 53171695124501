import { GetI18n } from "../../utils/mixedControl";

export function productCategoryTabBarModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;
  if (data && !requestData.hasFailed) {
    return {
      productCategoryTabBar: productCategoryTabBarMainModel(data, languageID),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function productCategoryTabBarMainModel(data, languageID) {
  if (data) {
    const {
      actionURL,
      code,
      filterProductCategoryID,
      id,
      menuItems,
      name,
      sortOrder,
    } = data;
    return {
      actionURL: actionURL ? actionURL : null,
      code : code ? code : null,
      filterProductCategoryID: filterProductCategoryID ? filterProductCategoryID : null,
      id,
      menuItems: menuList(menuItems, languageID),
      name: name ? name : null,
      sortOrder: sortOrder ? sortOrder : null,
    };
  }
}

function menuList(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const {
        id,
        url,
        code,
        productCategoryID,
        sortOrder,
        mobileImagePath,
        webImagePath,
      } = item;
      return {
        name: GetI18n(item, "name", languageID),
        id,
        url: url || "/",
        code: code ? code : null,
        sortOrder: sortOrder ? sortOrder : null,
        mobileImagePath: mobileImagePath ? mobileImagePath : null,
        webImagePath: webImagePath ? webImagePath : null,
        productCategoryID: productCategoryID ? productCategoryID : null,
      };
    });
  }
  return newData;
}
