import { GetI18n } from "../../utils/mixedControl";
import { seoModel } from "../partnerComponents/seo";

export function homePageModel(requestData, languageID) {
  const data = requestData ? requestData : false;
  if (data && !requestData.hasFailed) {
    const {
      code,
      countries,
      documents,
      headlines,
      id,
      productCategories,
      productCategoryID,
      products,
      slugURL,
      solutionCategories,
      sortOrder,
      url,
      widgets,
      seoTag,
    } = data;
    return {
      code: code ? code :  null,
      countries: countries ? countries : null,
      documents: documentsModel(documents, languageID),
      headlines: headlinesModel(headlines, languageID),
      id,
      name: GetI18n(data, "name", languageID),
      productCategories: productCategoriesModel(productCategories, languageID),
      productCategoryID: productCategoryID ? productCategoryID : null,
      products: productModel(products, languageID),
      slugURL: slugURL ? slugURL : null,
      solutionCategories: solutionCategoriesModel(
        solutionCategories,
        languageID
      ),
      sortOrder: sortOrder ? sortOrder : null,
      seoTag: seoModel(seoTag, languageID),
      url: url ? url : null,
      widgets: widgetsModel(widgets, languageID),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

export function documentsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { id, filePath, imagePath, sortOrder } = item;
      return {
        title: GetI18n(item, "title", languageID),
        id,
        filePath: filePath ? filePath : null,
        imagePath: imagePath ? imagePath : null,
        sortOrder: sortOrder ? sortOrder : null,
      };
    });
  }
  return newData;
}
export function headlinesModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { id, imagePath } = item;
      return {
        title: GetI18n(item, "title", languageID),
        id,
        imagePath: imagePath ? imagePath : null,
      };
    });
  }
  return newData;
}

function productCategoriesModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { id, slugURL, sortOrder } = item;
      return {
        name: GetI18n(item, "name", languageID),
        id,
        slugURL: slugURL ? slugURL : null,
        sortOrder: sortOrder ? sortOrder : null,
      };
    });
  }
  return newData;
}

export function productModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const {
        id,
        slugURL,
        sortOrder,
        document,
        productCategory,
        video,
        videoID,
        imagePath,
      } = item;
      return {
        name: GetI18n(item, "name", languageID),
        id,
        slugURL: slugURL ? slugURL : null,
        sortOrder: sortOrder ? sortOrder : null,
        document: documentModel(document, languageID),
        productCategory: productCategoryModel(productCategory, languageID),
        shortDescription: GetI18n(item, "shortDescription", languageID),
        video: video ? video : null,
        videoID: videoID ? videoID : null,
        imagePath: imagePath ? imagePath : null,
        description: GetI18n(item, "description", languageID),
      };
    });
  }
  return newData;
}
function productCategoryModel(data, languageID) {
  if (data) {
    const { id, slugURL, sortOrder } = data;
    return {
      id,
      slugURL: slugURL ? slugURL : null,
      sortOrder: sortOrder ? sortOrder : null,
      name: GetI18n(data, "name", languageID),
    };
  }
}
function documentModel(data, languageID) {
  if (data) {
    const { id, filePath, imagePath, sortOrder } = data;
    return {
      id,
      filePath: filePath ? filePath : null,
      imagePath: imagePath ? imagePath : null,
      sortOrder: sortOrder ? sortOrder : null,
      title: GetI18n(data, "title", languageID),
    };
  }
}

export function solutionCategoriesModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { id, slugURL, sortOrder, solutions } = item;
      return {
        name: GetI18n(item, "name", languageID),
        id,
        slugURL: slugURL ? slugURL : null,
        sortOrder: sortOrder ? sortOrder : null,
        solutions: solutionsModel(solutions),
      };
    });
  }
  return newData;
}
function solutionsModel(data) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { id, slugURL, sortOrder, solutionCategoryID } = item;
      return {
        id,
        slugURL: slugURL ? slugURL : null,
        sortOrder: sortOrder ? sortOrder : null,
        solutionCategoryID: solutionCategoryID ? solutionCategoryID : null,
      };
    });
  }
  return newData;
}
export function widgetsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const {
        id,
        slugURL,
        sortOrder,
        backgroundURL,
        code,
        contents,
        url,
        imagePath,
      } = item;
      return {
        name: GetI18n(item, "name", languageID),
        title: GetI18n(item, "title", languageID),
        subTitle: GetI18n(item, "subTitle", languageID),
        description: GetI18n(item, "description", languageID),
        id,
        slugURL: slugURL ? slugURL : null,
        sortOrder: sortOrder ? sortOrder : null,
        backgroundURL: backgroundURL ? backgroundURL : null,
        code: code ? code : null,
        contents: contentsModel(contents, languageID),
        url: url ? url : null,
        imagePath: imagePath ? imagePath : null,
      };
    });
  }
  return newData;
}

function contentsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        ...item,
        title: GetI18n(item, "title", languageID),
      };
    });
  }
  return newData;
}
