import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import Service from "../../service";
import { ourHistoryPageModel } from "../../shared/models/mainComponents/ourHistoryPageModel";

const hydrate = createAction(HYDRATE);

const ourHistoryPageSlice = createSlice({
  name: "ourHistory",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.ourHistory,
      };
    });
  },
});

export const ourHistoryPageData = (state) => state.ourHistory;
export const { setItemList, failed } = ourHistoryPageSlice.actions;

export default ourHistoryPageSlice.reducer;

export function getOurHistoryData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.content
      .getGetPage({
        data: {
          code: "ourHistory",
        },
      })
      .then((res) => {
        const newData = ourHistoryPageModel(res, ctx.userSettings.languageID);

        if (res?.data && !res.hasFailed) {
          dispatch(setItemList(newData));
        } else {
          dispatch(failed(res?.messages?.[0]?.description));
        }
      });
  };
}
