import { css } from "styled-components";

//center
const displayCenter = `
    display: flex;  
    align-items: center;`;

const displayJustCenter = `
    display: flex; 
    justify-content: center;`;

const displayCenterCenter = `
    display: flex;
    align-items: center;
    justify-content: center;`;

//column
const displayColumn = `
  display: flex;
  flex-direction: column;`;

const displayColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;`;

const displayColumnBetween = `
  display: flex;
  flex-direction: column;
  justify-content: space-between;`;

const displayColumnJustCenter = `
  display: flex;
  flex-direction: column;
  justify-content: center;`;

// end
const displayEnd = `
  display: flex;
  justify-content: flex-end;`;

const displayEndCenter = `
  display: flex;
  justify-content: flex-end;
  align-items: center;`;

// between
const displayBetween = `
  display: flex;
  justify-content: space-between;`;

const displayBetweenCenter = `
  display: flex;
  align-items: center;
  justify-content: space-between;`;

// evenly
const displaySpaceEvenly = `
  display: flex;
  justify-content: space-evenly;`;

//wrap
const displayWrap = `
  display: flex;
  flex-wrap: wrap;`;

const displayWrapCenter = `
  display: flex;
  flex-wrap: wrap;
  justify-content: center;`;

const displayWrapBetween = `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;`;

//full
const displayFull = `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;`;

// images
const imgCoverCenter = `
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;`;

const imgContainCenter = `
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  -o-background-size: contain;
`;

const backgroundAuto = `
  -webkit-background-size: auto;
  -moz-background-size: auto;
  background-size: auto;
  -o-background-size: auto;`;

// general
const selectClose = `
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/`;

const slow300 = ` transition: all 300ms linear;`;

/////////////////// button //////////////////////////

//ant  button
const antBtnGeneralContainer = `
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border-radius: 35px;
  padding: 0 17px;
  border: none;
  text-shadow: none;
  box-shadow: none;
  margin: 20px 0;
  max-width:100%;
  
  &[disabled] {
    border-color: transparent !important;
    span {
      color: #fff;
    }
  }

  
  & span {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-form-item {
    width: 100%;
  }`;

//position left side

const calculateLeftSide = `
  margin-left: calc(((100% - 1320px) / 2));
`;

//////////////////////// scrolll /////////////////////

const scrollTransparent = `
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;}
  

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px};
  

  &::-webkit-scrollbar-thumb{ 
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px};
  

  &::-webkit-scrollbar-thumb:hover{ 
    background: $scroll-primary;}`;

const scrollYGeneral = `
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-track {
    background: #f7f6f6;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-thumb {
    background: #365097;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-thumb:hover {
    background: #385ab5;}`;
const scrollYBlue = `
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-track {
    background: #f7f6f6;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-thumb {
    background: $scroll-primary;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-primary;}`;

const scrollXBlue = `
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;}

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;}

  &::-webkit-scrollbar-thumb {
    background: $scroll-primary;
    border-radius: 5px;}

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-primary;}
  `;

const scrollXGeneral = `
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 5px;}
  
  &::-webkit-scrollbar-track {
    background: #f7f6f6;
    border-radius: 8px;}
  
  &::-webkit-scrollbar-thumb {
    background: #ff002b;
    border-radius: 8px;}
  
  &::-webkit-scrollbar-thumb:hover {
    background: #ff002b;}`;

const linearBG = `
  background-image: linear-gradient(
    108deg,
    #ff002b -36%,
    #ef194c 15%,
    #013549 125%
  );
`;

//slider arrows
const arrowPositionPrev = `
  left: inherit;
  right: 52%;
  top: auto;
  bottom: -87px;


  .iconMain{
    ${displayCenterCenter};
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #565E6D99;
    width: 40px;
    height: 40px;

    &:hover{
      border: 2px solid #365097;

      .icon{
        fill: #365097;
      }
    }

    .icon {
      width: 26px;
      height: 26px;
      fill: #565E6D99;
    }
  }  
`;

const arrowPositionNext = `
   left: 50%;
   top: auto;
   bottom: -87px;

  .iconMain{
    ${displayCenterCenter};
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #565E6D99;
    width: 40px;
    height: 40px;

    &:hover{
      border: 2px solid #365097;

      .icon{
        fill: #365097;
      }
    }

    .icon {
      width: 26px;
      height: 26px;
      fill: #565E6D99;
    }
  }  
`;

const propsSettings = (params) => css`
  ${params}
`;

const extendStyled = {
  displayCenter: propsSettings(displayCenter),
  displayJustCenter: propsSettings(displayJustCenter),
  displayCenterCenter: propsSettings(displayCenterCenter),
  displayColumn: propsSettings(displayColumn),
  displayColumnCenter: propsSettings(displayColumnCenter),
  displayColumnBetween: propsSettings(displayColumnBetween),
  displayColumnJustCenter: propsSettings(displayColumnJustCenter),
  displayEnd: propsSettings(displayEnd),
  displayEndCenter: propsSettings(displayEndCenter),
  displayBetween: propsSettings(displayBetween),
  displayBetweenCenter: propsSettings(displayBetweenCenter),
  displaySpaceEvenly: propsSettings(displaySpaceEvenly),
  displayWrap: propsSettings(displayWrap),
  displayWrapCenter: propsSettings(displayWrapCenter),
  displayWrapBetween: propsSettings(displayWrapBetween),
  displayFull: propsSettings(displayFull),
  calculateLeftSide: propsSettings(calculateLeftSide),
  imgCoverCenter: propsSettings(imgCoverCenter),
  imgContainCenter: propsSettings(imgContainCenter),
  backgroundAuto: propsSettings(backgroundAuto),
  selectClose: propsSettings(selectClose),
  scrollTransparent: propsSettings(scrollTransparent),
  scrollYBlue: propsSettings(scrollYBlue),
  scrollXBlue: propsSettings(scrollXBlue),
  scrollXGeneral: propsSettings(scrollXGeneral),
  scrollYGeneral: propsSettings(scrollYGeneral),
  slow300: propsSettings(slow300),
  antBtnGeneralContainer: propsSettings(antBtnGeneralContainer),
  linearBG: propsSettings(linearBG),
  arrowPositionPrev: propsSettings(arrowPositionPrev),
  arrowPositionNext: propsSettings(arrowPositionNext),
};
export default extendStyled;
