import React, { useCallback, useEffect, useState } from "react";
import { FooterContentStyled } from "../../shared/layout/layoutMain/styled/layoutMainFooterStyled.styled";
import Logo from "../../shared/partnerComponents/icons/logo";
import Icon from "../../shared/partnerComponents/icons/icon";
import { useRouter } from "next/router";
import { Empty, Menu } from "antd";
import { useWindowSize } from "../../shared/utils/mixedControl";
import CustomLink from "../../shared/partnerComponents/customLink";

const FooterContent = ({ footerListData }) => {
  const [footerList, setFooterList] = useState(null);
  const breakpoint = useWindowSize()?.width > 719 ? true : false;
  const router = useRouter();

  const callFooter = useCallback(() => {
    setFooterList(footerListData);
  }, [footerListData]);
  
  useEffect(() => {
    callFooter();
  }, [footerListData, callFooter]);

  useEffect(() => {
    const handleRouteChange = () => {
      setFooterList(null);
      callFooter();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, callFooter]);

  const handleRoute = useCallback(
    (item) => {
      const section = document.getElementById("navbar");
      const SolutionCategoryID = item?.solutionCategoryID || "";

      if (SolutionCategoryID) {
        router
          .push({
            pathname: `${item?.url}`,
            query: { SolutionCategoryID },
          })
          .then(() => {
            setFooterList(null);
            callFooter();
            if (section) {
              section.scrollIntoView();
            }
          });
      } else {
        router.push(item.url).then(() => {
          setFooterList(null);
          callFooter();
          if (section) {
            section.scrollIntoView();
          }
        });
      }
    },
    [router, callFooter]
  );

  if (!footerList) return null;

  return (
    <FooterContentStyled className="footerContent">
      <div className="footerContent-container">
        <div className="footerContent-container-FooterImageMenu">
          {footerList?.item?.imageMenu?.menuItems?.map((item, ind) => {
            const { code } = item;
            return (
              <React.Fragment key={ind}>
                {code === "footerLogo" ? (
                  <>
                    <Logo color="#1C3B84" className={"footerLogo"} />
                    {footerList?.item?.data?.map((social, index) => (
                      <div key={index}>
                        {!breakpoint && social.code === "FooterFollowUsMenu" && (
                          <div className="footerContent-container-FooterCorporateMenu-social">
                            {footerList?.item?.socialMediaMenu?.menuItems.map(
                              (social, i) => (
                                <Icon
                                  key={i}
                                  onClick={() => {
                                    if (typeof window !== "undefined") {
                                      window.open(social.url, "_blank");
                                    }
                                  }}
                                  name={`icon-${social.code === "twitter" ? "x" : social.code}`}
                                />
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="footerContent-container-FooterImageMenu-imageMenu">
                    <div className="footerContent-container-FooterImageMenu-imageMenu-icon">
                      <Icon
                        name={`icon-${
                          code === "address"
                            ? "konum"
                            : code === "phoneNumber"
                            ? "phone"
                            : code
                        }`}
                      />
                    </div>
                    <div className="footerContent-container-FooterImageMenu-imageMenu-content">
                      {item.code === "phoneNumber" ? (
                        <div>
                          {item?.title
                            ?.split("\r\n")
                            .map((phoneNumber, i) => (
                              <span key={i}>
                                <CustomLink href={`tel:${phoneNumber}`}>
                                  {phoneNumber}
                                </CustomLink>
                                <br />
                              </span>
                            ))}
                        </div>
                      ) : item.code === "map" ? (
                        <p
                          onClick={() => {
                            if (item.url && typeof window !== "undefined") {
                              window.open(item.url, "_blank");
                            }
                          }}
                          className="footerContent-container-FooterImageMenu-imageMenu-content-map"
                        >
                          {item.title}
                        </p>
                      ) : (
                        <div>
                          <CustomLink
                            href={
                              item.code === "email"
                                ? `mailto:${item.name}`
                                : item.url
                                ? item.url
                                : "/"
                            }
                          >
                            {item.title}
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {footerList?.item?.data?.map((item, index) => (
          <div key={index}>
            {breakpoint ? (
              <div
                style={{
                  marginLeft: item?.type == "corporateMenu" ? "125px" : "0px",
                }}
                className="footerContent-container-FooterCorporateMenu"
              >
                <p className="footerContent-container-FooterCorporateMenu-title">
                  {item.name}
                </p>

                {item?.menuItems?.map((data, index) => (
                  <div
                    onClick={() => handleRoute(data)}
                    key={index}
                    className={`footerContent-container-FooterCorporateMenu-subTitle`}
                  >
                    <p>{data.title}</p>
                  </div>
                ))}
                {item.code === "FooterFollowUsMenu" && (
                  <div className="footerContent-container-FooterCorporateMenu-social">
                    {footerList?.item?.socialMediaMenu?.menuItems.map(
                      (social, i) => (
                        <Icon
                          key={i}
                          onClick={() => window.open(social.url, "_blank")}
                          name={`icon-${
                            social.code === "twitter" ? "x" : social.code
                          }`}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="footerContent-container-menu">
                <Menu
                  defaultSelectedKeys={["0"]}
                  mode="inline"
                  key={item.sortOrder}
                >
                  <Menu.SubMenu key={item.sortOrder} title={item.name}>
                    {item?.menuItems?.map((data, index) => (
                      <Menu.Item
                        onClick={() => {
                          handleRoute(data);
                        }}
                        key={index}
                      >
                        {data.title}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </Menu>
              </div>
            )}
          </div>
        ))}
      </div>
    </FooterContentStyled>
  );
};

export default FooterContent;
