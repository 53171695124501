import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { initialObjectState } from "../initialState";
import { legalNoticePageModel } from "../../shared/models/mainComponents/legalNoticePageModel";

const hydrate = createAction(HYDRATE);

const legalNoticePageSlice = createSlice({
  name: "legalNotice",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.legalNotice,
      };
    });
  },
});

export const legalNoticeData = (state) => state.legalNotice;
export const { setItemList, failed } = legalNoticePageSlice.actions;

export default legalNoticePageSlice.reducer;

export function getLegalNoticeData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.content
      .getGetPage({
        data: {
          code: "legalNotice",
        },
      })
      .then((res) => {
        const newData = legalNoticePageModel(res, ctx.userSettings.languageID);

        if (res?.data && !res.hasFailed) {
          dispatch(setItemList(newData));
        } else {
          dispatch(failed(res?.messages?.[0]?.description));
        }
      });
  };
}
