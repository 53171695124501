import { GetI18n } from "../../utils/mixedControl";

export function seoAPIModel(data, url, languageID) {
  const selectData = data?.[0];
  if (selectData) {
    return {
      url,
      metaTags: GetI18n(selectData, "metaTags", languageID),
      title: GetI18n(selectData, "title", languageID),
      description: GetI18n(selectData, "description", languageID),
    };
  }
  return {
    url,
  };
}
