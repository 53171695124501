import styled from "@emotion/styled";
import settingsStyled from "../../../../../styled/settings.styled"
export const LogoStyled = styled.div`
  .logo {
    &__imgDesk {
      max-width: 255px;
      max-height: 70px;
      @media ${settingsStyled.m1023} {
        display: none;
      }
    }
    &__imgMobil {
      max-width: 98px;
      max-height: 31px;
      display: none;
      @media ${settingsStyled.m1023} {
        display: block;
      }
    }
  }
`;
