import { createSlice, createAction } from "@reduxjs/toolkit";
import { initialObjectState } from "../initialState";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";

const hydrate = createAction(HYDRATE);

const translationResourceSlice = createSlice({
  name: "translation",
  initialState: {
    ...initialObjectState,
    loading: true,
  },
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setUrlItem: (state, { payload }) => {
      state.urlItem = payload;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.translation,
      };
    });
  },
});

export const translationData = (state) => state.translation;
export const { setItemList, setUrlItem, failed } = translationResourceSlice.actions;

export default translationResourceSlice.reducer;

export function getTranslationResource(languageID, ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    dispatch({ type: "translation/loading" });
    await service.configuration
      .getTranslationResource(`langID=${languageID}&prefix=Site.`)
      .then((translationData) => {
        if (translationData) {
          let newData = {};

          for (const key in translationData) {
            newData = { ...newData, [key.toLowerCase()]: translationData[key] };
          }

          dispatch(setItemList(newData));
        } else {
          dispatch(failed("Failed to load translation data"));
        }
      })
      .catch((error) => {
        dispatch(failed(error.toString()));
      });
  };
}
