import React, { memo } from "react";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { IconStyled } from "./styled/icon.styled";

const Icon = ({
  name,
  style,
  className,
  onClick,
  title,
  titleGetwordClose,
}) => {
  const { GetWord } = getWordControl();
  return (
    <IconStyled
      title={title && (!titleGetwordClose ? GetWord(title) : title)}
      className="iconMain"
    >
      <svg
        className={"icon svg-icon " + (className || "")}
        aria-hidden="true"
        style={style}
        onClick={onClick}
      >
        <use href={"#" + name}></use>
      </svg>
    </IconStyled>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default memo(Icon);
