import baseService from "./baseService";

export default class MembershipService extends baseService {
  constructor(service) {
    super(service);
    this.schemaName = "membership";
  }

  myProfile(entity) {
    return this.postObject(this.schemaName, "MyProfile", entity);
  }
  SetUpdateNewsletterEmail(entity) {
    return this.postObject(this.schemaName, "UpdateNewsletterEmail", entity);
  }
  GetPassiveENewsletterMail(entity) {
    return this.postObject(this.schemaName, "PassiveENewsletterMail", entity);
  }
}
