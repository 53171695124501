import AddressBookService from "./addressBook";
import CatalogService from "./catalog";
import ConfigurationService from "./configuration";
import ContentService from "./content";
import MembershipService from "./membership";

export default class Service {
  context = null;

  constructor(context) {
    this.context = context;
  }

  configuration = new ConfigurationService(this);
  content = new ContentService(this);
  membership = new MembershipService(this);
  catalog = new CatalogService(this);
  addressbook = new AddressBookService(this);
}
