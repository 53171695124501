import baseService from "./baseService";

export default class ConfigurationService extends baseService {
  constructor(service) {
    super(service);
    this.schemaName = "Configuration";
  }

  initApp(entity) {
    return this.postObject(this.schemaName, "InitApp", entity);
  }
  getTranslationResource(entity) {
    return this.getObject(this.schemaName, "GetTranslationResource", entity);
  }

  increaseTranslationAccesses(entity) {
    return this.getObject(
      this.schemaName,
      "IncreaseTranslationAccesses",
      entity
    );
  }
  updateTranslation(entity) {
    return this.postObject(this.schemaName, "UpdateTranslation", entity);
  }
}
