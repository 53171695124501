export const variablesStyled = {
  //font Color
  errorRed: "#ff4d4f",
  successGreen: "#4caf50",
  warningYellow: "#faad14",
  infoBlue: "#1890ff",

  //icon color
  iconPrimary: "#151140",

  blue: "#89AFEC",
  secondary: "#B6CDF4",
  title: "#252525",
  tab: "#413E61",
  primary: "#151140",
  tag: "#023047",
  white: "#FFF",
  grey: "#F7F8FC",
  blueBold: "#0D5EE3",
  blueBlur: "#3F516D",
  orangeLight: "#FFEDCE",
  orange: "#FF8D17",
  blueLight: "#D3E4FF",
  blueTitle: "#357bda",
  blueSky: "#F1F4FD",
  text: "#7B7B7B",
  purple: "#736CC3",
  breadcrumb: "#36517A",
  dark: "linear-gradient(136.66deg, #524E7C 2.73%, #151140 100%)",
  cross: "linear-gradient(122.92deg, #736CC4 -1.43%, #151140 104.76%)",
  android: "linear-gradient(254.54deg, #499EB5 -2.93%, #6CD1BD 105.91%)",
  ios: "linear-gradient(117.88deg, #19B7EF -4.35%, #0853CA 98.83%)",
  native:
    " linear-gradient(133.53deg, #0D5EE3 -27.16%, rgba(45, 124, 255, 0.89) 100.87%)",
  hybrid: "linear-gradient(180deg, #FFB627 0%, #FF7F11 100%)",
  blue3: "#3F7CE0", // "linear-gradient(90deg, #3F7CE0 -3.5%, #2B79D5 105.39%)",

  milestones : "linear-gradient(90deg, #3F7CE0 -3.5%, #2B79D5 105.39%);",
  //background color

  //background  images

  //border color

  //scroll color
  scrollPrimary: "#13385a",

  //font family
  // fontFamilyPrimary: "Social-Poppins",

  //heigth width
  iconHeigthWidth: "24px",

  //font size
  fontSizePrimary: "16px",

  // form settings
  formFontColor: "#000",
  formBg: "#fff",
  formBorder: "#99A6BB",
  formBorderFocus: "#596b75",
  formFontSize: "12px",
  formLabelColor: "#000",
  formlabelFontSize: "16px",
  formHeight: "45px",
  formBorderRadius: "8px",
  formTextAreaHeight: "90px",
  formCheckBoxBg: "#151140",
  formDatePickerselectBg: "#151140",
  formRadioselectBg: "#151140",
  formSwitchBg: "#151140",
};
