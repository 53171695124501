import styled from "styled-components";
import settingsStyled from "../../Assets/styled/settings.styled";

export const SearchResultStyled = styled.section`
  max-width: 1040px;
  width: 1040px;
  margin: 0 auto 30px auto;

  @media ${settingsStyled.m1200} {
    padding: 0 20px;
  }

  @media ${settingsStyled.m1023} {
    max-width: 100%;
    width: 100%;
  }

  @media ${settingsStyled.m479} {
    margin: 0;
  }
  .searchResult {
    &-titleContainer {
      margin-block: 56px;
      @media ${settingsStyled.m1200} {
        margin-block: 46px;
      }

      @media ${settingsStyled.m959} {
        margin-block: 36px;
      }

      @media ${settingsStyled.m767} {
        margin-block: 26px;
      }
      &__titleResult {
        color: #1c3b84;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;

        @media ${settingsStyled.m1200} {
          font-size: 18px;
          line-height: 23px;
        }

        @media ${settingsStyled.m959} {
          font-size: 16px;
          line-height: 21px;
        }

        @media ${settingsStyled.m767} {
          font-size: 14px;
          line-height: 19px;
        }

        span {
          color: #1c3b84;
          font-size: 22px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0em;

          @media ${settingsStyled.m1200} {
            font-size: 20px;
            line-height: 23px;
          }

          @media ${settingsStyled.m959} {
            font-size: 18px;
            line-height: 21px;
          }

          @media ${settingsStyled.m767} {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
    &-items {
      max-height: 65%;
      ${settingsStyled.scrollYGeneral}

      @media ${settingsStyled.m767} {
        max-height: 75%;
      }

      .searchResult-itemResult {
        margin-bottom: 30px;
        &__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-image {
            min-width: 76px;
            min-height: 46px;
            width: 76px;
            height: 46px;
            border-radius: 4px;
            margin-right: 24px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }
          &-info {
            flex: auto;
            &:hover {
              .hovering {
                color: #365097;
              }
            }
            &__title {
              font-size: 18px;
              font-weight: 500;
              line-height: 25px;
              letter-spacing: 0em;
              text-align: left;
              color: #343434;

              @media ${settingsStyled.m959} {
                font-size: 16px;
                line-height: 21px;
              }

              @media ${settingsStyled.m767} {
                font-size: 14px;
                line-height: 19px;
              }

              span {
                font-size: 18px;
                line-height: 25px;

                @media ${settingsStyled.m959} {
                  font-size: 16px;
                  line-height: 21px;
                }

                @media ${settingsStyled.m767} {
                  font-size: 14px;
                  line-height: 19px;
                }
              }
            }

            &__summary {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: left;

              @media ${settingsStyled.m959} {
                font-size: 13px;
                line-height: 18px;
              }

              @media ${settingsStyled.m767} {
                font-size: 12px;
                line-height: 16px;
              }
            }

            &__subtitle {
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              width: 70%;

              @media ${settingsStyled.m479} {
                display: none;
              }
            }
          }

          .icon {
            width: 30px;
            height: 30px;
            margin-right: 50px;

            @media ${settingsStyled.m767} {
              width: 26px;
              height: 26px;
              margin-right: 20px;
            }
          }
        }
      }
    }

    &-noSearch {
      padding: 40px 0 20px 0;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;
