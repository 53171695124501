import { memo, useEffect } from "react";
import { LayoutMainFooterStyled } from "../styled/layoutMainFooterStyled.styled";
import FooterContent from "../../../../components/Footer/footerContent";
import { ContainerGeneralStyled } from "../../../../Assets/styled/general.styled";
import { useDispatch, useSelector } from "react-redux";
import { footerData, getFooterData } from "../../../../store/private/footerSlice";
import { getLanguageID } from "../../../utils/common";

const LayoutMainFooter = memo(() => {
  const footerListData = useSelector(footerData);
  const copyRight = footerListData?.item?.description?.message;
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const updatedCopyRight = copyRight?.replace("{0}", currentYear);

  useEffect(() => {
    const languageID = getLanguageID();
    const qq = {
      userSettings: {
        languageID: languageID,
      },
    };
    dispatch(getFooterData(qq));
  }, [dispatch]);

  return (
    <LayoutMainFooterStyled className="layoutMainFooter">
      {footerListData?.item?.data && (
        <ContainerGeneralStyled className="layoutMainFooter-container-content">
          <FooterContent footerListData={footerListData} />
        </ContainerGeneralStyled>
      )}
      <div className="layoutMainFooter-container-rectangle">
        <div className="layoutMainFooter-container-rectangle-copyRight">
          <p>
            {updatedCopyRight
              ? ` © ${updatedCopyRight}`
              : `© ${currentYear} Ersey Makina • KVKK • Yasal Uyarı`}
          </p>
        </div>

        <p
          onClick={() => {
            if (typeof window !== "undefined") {
              window.open("http://www.kartega.com/", "_blank");
            }
          }}
          className="layoutMainFooter-container-rectangle-powered"
        >
          Powered by Kartega <sup>TM</sup>
        </p>
        <div className="layoutMainFooter-container-rectangle-clip">
          <div className="layoutMainFooter-container-rectangle-clip-left"></div>
          <div className="layoutMainFooter-container-rectangle-clip-right"></div>
        </div>
      </div>
    </LayoutMainFooterStyled>
  );
});

export default LayoutMainFooter;
