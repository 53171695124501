import baseService from "./baseService";

export default class CatalogService extends baseService {
  constructor(service) {
    super(service);
    this.schemaName = "catalog";
  }

  GetProductCategory(entity) {
    return this.postObject(this.schemaName, "GetProductCategory", entity);
  }
  GetProduct(entity) {
    return this.postObject(this.schemaName, "GetProduct", entity);
  }

  GetSolution(entity) {
    return this.postObject(this.schemaName, "GetSolution", entity);
  }
}
