import styled from "styled-components";
import settingsStyled from "./settings.styled";

// 1200
export const ContainerGeneralStyled = styled.div`
  max-width: 1240px;
  margin: auto;
  padding: 0 20px;

  @media ${settingsStyled.m959} {
    padding: 0 20px;
    margin: initial;
  }
  @media ${settingsStyled.m479} {
    margin: auto;
  }
`;

// 1110
export const ContainerSmallStyled = styled.div`
  max-width: 1110px;
  margin: auto;
  padding: 0 20px;
`;

// 1360
export const ContainerBigStyled = styled.div`
  max-width: 1360px;
  margin: auto;
  padding: 0 20px;
`;
