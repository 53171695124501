import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import Service from "../../service";
import { catalogsPageModel } from "../../shared/models/mainComponents/catalogsPageModel";

const hydrate = createAction(HYDRATE);

const catalogsPageSlice = createSlice({
  name: "catalogs",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setCatologId: (state, { payload }) => {
      state.catologId =  payload || 1,
      state.loading = false,
      state.hasErrors = false,
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.catalogs,
      };
    });
  },
});
export const { setItemList, failed, setCatologId } = catalogsPageSlice.actions;

export const catalogsPageData = (state) => state.catalogs.item;
export const selectCatologId = (state) =>state.catalogs.catologId;
export default catalogsPageSlice.reducer;

export function getDocumentData(ctx) {
  return async (dispatch, getState) => {
    const service = new Service(ctx);
    const state = getState();
    const catologId = state.catalogs.catologId;

    await service.content
    .getDocumentModel({
      data: {
        productCategoryID: catologId,
      },
    })
    .then((res) => {
      const newData = catalogsPageModel(res, ctx.userSettings.languageID);
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}