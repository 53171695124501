
const useIconName = (name) => {
  switch (name) {
    case 2:
      return "a-gazsiz_icecek";
    case 3:
      return "a-gazl_icecek";
    case 7:
      return "a-siviyag";
    case 6:
      return "gunluk_kozmetik";
    case 1:
      return "su";
    case 4:
      return "meyve_suyu";
    case 5:
      return "maden_suyu";
    case 26:
      return "users-profiles";
    case 27:
      return "globe";
    case 28:
      return "line-chart-up";
    case 29:
      return "settings";
    case 30:
      return "clock";
    case "corporate":
      return "kurumsal";
    case "products":
      return "a-urunler";
    case "mediaCenter":
      return "a-medyamerkezi";
    case "communication":
      return "a-iletisim";
    default:
      return "defaultIcon";
  }
};

export default useIconName;
