import { GetI18n, sortOrderString } from "../../utils/mixedControl";

//selectBox
// sortOrderSize name göre sortOrder yapıyor = small / big değerlerini alır
// languageID
// propName = name yerine başka bir değer atamak istiyorsak
// propNameTwo = ikici yan kelimeyi ayarlıyor.
// otherStatus = diğer seçeneğini ekliyor.

export function selectBoxModel(
  data,
  languageID,
  propName,
  idPropName,
  propNameTwo,
  sortOrderSize,
  otherStatus
) {
  const list = [];

  if (data?.length > 0) {
    data.forEach((element) => {
      list.push({
        id: element[idPropName || "id"],
        name:
          GetI18n(element, propName || "name", languageID) +
          (propNameTwo
            ? " - " + GetI18n(element, propNameTwo, languageID)
            : ""),
        fullData: element,
      });
    });
  }

  const newList = otherStatus
    ? [...list, { id: "other", name: "other" }]
    : list;

  return sortOrderSize
    ? sortOrderString(newList, "name", sortOrderSize)
    : newList;
}
