import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import Service from "../../service";
import { layoutMainHeaderModel } from "../../shared/models/layout/layoutMainHeaderModel";
import Auth from "../../shared/auth";

const hydrate = createAction(HYDRATE);

const headerNavbarSlice = createSlice({
  name: "navbar",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.navbar,
      };
    });
  },
});

export const navbarData = (state) => state.navbar;
export const { setItemList, failed } = headerNavbarSlice.actions;

export default headerNavbarSlice.reducer;

export function getNavbarData(ctx) {
  return async (dispatch, getState) => {
    await Auth({ ctx, store: { dispatch, getState } }, false, false);
    const service = new Service(ctx);
    const menuRequestData = {
      data: {},
    };
    await service.content.GetAllMenuItems(menuRequestData).then((res) => {
      const newData = layoutMainHeaderModel(res, ctx.userSettings.languageID);
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
