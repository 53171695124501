import styled from "@emotion/styled";
import settingsStyled from "../../../../styled/settings.styled";

export const LoadingStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  /* antd */
  .ant-spin {
    line-height: 1;
  }

  &.loading--innerPage {
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 100%;
    ${settingsStyled.displayCenterCenter};
  }
`;
