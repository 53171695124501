import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import initAppSlice from "./general/initAppSlice";
import translationResourceSlice from "./general/translationResourceSlice";
import headerMenuSlice from "./general/headerMenuSlice";
import seoTagsSlice from "./general/seoTagsSlice";
import userSlice from "./private/userSlice";
import homePageSlice from "./private/homePageSlice";
import productCategoriesSlice from "./private/productCategoriesSlice";
import contactPageSlice from "./private/contactPageSlice";
import headerNavbarSlice from "./private/headerNavbarSlice";
import footerSlice from "./private/footerSlice";
import productsPageSlice from "./private/productPageSlice";
import aboutusPageSlice from "./private/aboutusPageSlice";
import legalNoticePageSlice from "./private/legalNoticePageSlice";
import clarificationTextPageSlice from "./private/clarificationTextPageSlice";
import ourHistoryPageSlice from "./private/ourHistoryPageSlice";
import announcementsPageSlice from "./private/announcementsPageSlice";
import catalogsPageSlice from "./private/catalogsPageSlice";
import productVideosPageSlice from "./private/productVideosPageSlice";
import solutionsPageSlice from "./private/solutionsPageSlice";
const combinedReducer = combineReducers({
  initApp: initAppSlice,
  translation: translationResourceSlice,
  headerMenu: headerMenuSlice,
  seoTags: seoTagsSlice,
  userInformation: userSlice,
  homePage: homePageSlice,
  contactPage: contactPageSlice,
  productCategories: productCategoriesSlice,
  navbar: headerNavbarSlice,
  footer: footerSlice,
  products: productsPageSlice,
  aboutUs: aboutusPageSlice,
  legalNotice: legalNoticePageSlice,
  clarificationText: clarificationTextPageSlice,
  ourHistory: ourHistoryPageSlice,
  announcements: announcementsPageSlice,
  catalogs: catalogsPageSlice,
  productVideosPage: productVideosPageSlice,
  solutions: solutionsPageSlice,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

const makeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()],
  });

  return store;
};

const wrapper = createWrapper(
  makeStore
  // { debug: true }
);
export default wrapper;