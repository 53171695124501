/* eslint-disable no-undef */
import axios from "axios";
import { getCookiesClient } from "../shared/utils/cookie/cookiesClient";
import { getCookiesServer } from "../shared/utils/cookie/cookiesServer";
const https = require("https");
const agent = new https.Agent({
  rejectUnauthorized: false,
});

export default class baseService {
  Service = null;
  
  constructor(service) {
    this.Service = service;
  }

  postObject(schemaName, funcName, entity) {
    const url = `${schemaName}/${funcName}`;
    return this.callServiceMethod(url, entity, "POST");
  }

  getObject(schemaName, funcName, data) {
    const url = `${schemaName}/${funcName}?${data || ""}`;
    return this.callServiceMethod(url, data, "GET");
  }

  callServiceMethod(urlPath, data, method) {
    const host =
      typeof window === "undefined"
        ? this.Service.context?.req?.headers?.host
        : window.location.host;
    let apiHost = null;

    if (
      host === "erseymakina.com" ||
      host === "kurumsal.erseymakina.com" ||
      host === "www.erseymakina.com"
    ) {
      apiHost = "https://kurumsalrouter.erseymakina.com/api/";
    } else {
      apiHost = "https://erseykurumsalrouter.kartega.com/api/";
    }

    const url = apiHost + urlPath;
    let token = null;

    if (typeof window !== "undefined" && host === "localhost:3000") {
      token = decodeURIComponent(getCookiesClient("token"));
    } else if (typeof window === "undefined" && this.Service?.context) {
      const { req, res } = this.Service.context;
      const selectToken = getCookiesServer(res, req, "token");
      token = selectToken || this.Service.context.userSettings?.token;
    }

    const headers = {
      ApplicationKey: "Site",
      AppKey:
        "667f080495499039da1cedabd69a78c3550cd151fbf759a1402df259553a572c04b91249f3cb5ca66f942b4a305e018494cfaead0d4bb65e1029ce3c2919386e",
    };

    if (token && token.length > 0) {
      headers.Authorization = `Bearer ${token}`;
    }

    return this.callAnyServiceMethod(url, data, method, headers);
  }

  async callAnyServiceMethod(url, data = {}, method, headers) {
    const authOptions = {
      method,
      url,
      data: { ...data },
      headers,
      withCredentials: true,
      httpsAgent: agent,
    };

    return await axios(authOptions)
      .then((response) => response.data)
      .catch((error) => {
        console.error("API error", error);
      });
  }
}
