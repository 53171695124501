import { createAction, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { initialHomePageState } from "../initialState"
import Service from "../../service";
import { failed } from "./userSlice";
import { homePageModel } from "../../shared/models/mainComponents/homePageModel";
import { getLanguageID } from "../../shared/utils/common";


const hydrate = createAction(HYDRATE)
const homePageSlice = createSlice({
    name:"homePage",
    initialState:initialHomePageState,
    reducers:{
        getHomeDataList:(state,{payload})=>{
            state.data = payload,
            state.loading =false,
            state.hasErrors=false,
            state.hasMessage = null
        },
        getItemDataFailed:(state,{payload})=>{
            state.loading =false,
            state.hasErrors=true,
            state.hasMessage = payload
        },
        setProductId: (state, { payload }) => {
            state.productId =  payload || 1,
            state.loading = false,
            state.hasErrors = false,
            state.hasMessage = null;
          },
        extraReducers(builder) {
            builder.addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.homePage,
                };
            });
        },

    }
});


export const { getHomeDataList, getItemDataFailed, setProductId } = homePageSlice.actions;


export const selectProductId = (state) =>state.homePage.productId;
export const selectHomeDataList =(state) =>state.homePage.data;
export default homePageSlice.reducer;
export function getHomeData(ctx) {
    return async (dispatch, getState) => {
      const service = new Service(ctx);
      const state = getState();
      const productId = state.homePage.productId;
      await service.content
      .getGetPage({
        data: {
          code: "homePage",
          productCategoryID: productId
        },
      })
      .then((res) => {
        if (res?.data && !res.hasFailed) {
          const newData = homePageModel(res.data, ctx?.userSettings ? ctx.userSettings.languageID: getLanguageID())
          dispatch(getHomeDataList(newData));
        } else {
          console.log("Error", res)
        }
      });
    };
  }