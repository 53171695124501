import { memo } from "react";
import LayoutMainFooter from "./layoutMainFooter";
import LayoutMainHeader from "./layoutMainHeader";
import LazyHydrate from "react-lazy-hydration";

const LayoutMain = memo(({ children }) => {
  return (
    <>
      <div
        style={{ width: "100%", justifyItems: "center", justifyContent: "center", alignItems: "center" }}
        className="layoutMain">
        <LayoutMainHeader />

        <main>{children}</main>

        <LazyHydrate whenVisible>
          <LayoutMainFooter />
        </LazyHydrate>
      </div>
    </>
  );
});

export default LayoutMain;
