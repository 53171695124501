import Cookies from "js-cookie";

export function setCookiesClient(name, value) {
  Cookies.set(name, value, { secure: true });
}

export function getCookiesClient(name) {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line no-undef
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
  }
  return null;
}
