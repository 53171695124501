/* eslint-disable no-undef */
export const getLocalstorage = (value) => {
  return JSON.parse(localStorage.getItem(value));
};

export const setLocalstorage = (value, data) => {
  localStorage.setItem(value, data ? JSON.stringify(data) : 1);
};

export const removeLocalstorage = (value) => {
  localStorage.removeItem(value);
};
