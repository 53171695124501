import { GetI18n } from "../../utils/mixedControl";

export function seoModel(data, languageID) {
  const selectData = data;
  if (selectData) {
    return {
      seoTagID: selectData.seoTagID || 1,
      url: selectData.url,
      metaTags: GetI18n(selectData, "metaTags", languageID),
      title: GetI18n(selectData, "title", languageID),
      description: GetI18n(selectData, "description", languageID),
    };
  }
}
