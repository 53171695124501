import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import Service from "../../service";
import { productVideosPageModel } from "../../shared/models/mainComponents/productVideosPageModel";
import { getLanguageID } from "../../shared/utils/common";


const hydrate = createAction(HYDRATE);

const productVideosSlice = createSlice({
  name: "productVideosPage",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setProductId: (state, { payload }) => {
      state.productId = payload || 1;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    getItemDataFailed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.productVideosPage,
      };
    });
  },
});

export const { setItemList, getItemDataFailed, setProductId } = productVideosSlice.actions;

export const selectProductId = (state) => state.productVideosPage.productId;
export const productVideosPageData = (state) => state.productVideosPage;

export default productVideosSlice.reducer;

export function getVideoModelData(ctx) {
  return async (dispatch, getState) => {
    const service = new Service(ctx);
    const state = getState();
    const productId = state.productVideosPage.productId;

    await service.content
      .getVideoModel({
        data: {
          productCategoryID: productId,
        },
      })
      .then((res) => {
        const newData = productVideosPageModel(res, ctx.userSettings.languageID);
        if (res?.data && !res.hasFailed) {
          dispatch(setItemList(newData));
        } else {
          dispatch(getItemDataFailed(res?.messages?.[0]?.description));
        }
      });

   
  };
}