import { GetI18n } from "../../utils/mixedControl";

export function layoutMainFooterModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const {
      corporateMenu,
      socialMediaMenu,
      followMenu,
      solutionCategoryMenu,
      imageMenu,
      productCategoryMenu,
      description,
    } = data;
    return {
      data: [
        {
          type: "corporateMenu",
          ...footerMainModel(corporateMenu, languageID),
        },
        {
          type: "solutionCategoryMenu",
          ...footerMainModel(solutionCategoryMenu, languageID),
        },
        {
          type: "productCategoryMenu",
          ...footerMainModel(productCategoryMenu, languageID),
        },
        { type: "followMenu", ...footerMainModel(followMenu, languageID) },
      ],
      imageMenu: footerMainModel(imageMenu, languageID),
      socialMediaMenu: footerMainModel(socialMediaMenu, languageID),
      description: footerMainModel(description, languageID),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function footerMainModel(data, languageID) {
  if (data) {
    const { id, code, sortOrder, menuItems } = data || {};
    return {
      id,
      name: GetI18n(data, "name", languageID),
      code,
      sortOrder,
      menuItems: menuList(menuItems, languageID),
      message: GetI18n(data, "message", languageID),
    };
  }
}

function menuList(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        ...item,
        title: GetI18n(item, "name", languageID),
      };
    });
  }
  return newData;
}
