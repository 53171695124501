import { createGlobalStyle } from "styled-components";
import settingsStyled from "./settings.styled";

export const GlobalStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
input,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-display: swap;
  font-size: ${settingsStyled.fontSizePrimary};
  color: ${settingsStyled.fontColorPrimary};
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  &:hover {
    color:${settingsStyled.fontColorPrimary};
  }
}

#root {
  min-height: 100vh;
  width: 100%;
}

/* auto seçince arka plan rengini ayarlama */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /*  border: 1px solid $font-white;
  -webkit-text-fill-color: $font-white; */
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-drawer-content{
  width: fit-content;
  @media ${settingsStyled.m479} {
    width: 390px;
  }
}

.layoutMainHeader{
  @media ${settingsStyled.m1023} {
    max-height: 66px;
  }
}
.ant-drawer-left > .ant-drawer-content-wrapper{
  box-shadow: none;
}
/* ant desing settings */

/* Ant Tab componenti için yapıldı. Version 4. */
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
    color: #5D7285 !important;

  }

.ant-tabs-nav{
  width: 100%;
  justify-content: center;
  align-items: center;
  @media ${settingsStyled.m767} {
    overflow: hidden;
    white-space: nowrap;
  }

   @media ${settingsStyled.m479} {
      padding-left: 0px;
      height: 66px !important;
   }

  &-list{
    gap: 40px;

    @media ${settingsStyled.m767} {
      gap: 20px;
    }
  }
}

.ant-tabs-tab {
  width: 148px;
  justify-content: center;
  align-items: center;
  margin: 0 !important;

  @media ${settingsStyled.m767} {
     width: 110px;
    }

    @media ${settingsStyled.m587} {
     width: 100px;
    }

  &-btn {
      color: #5D7285;
      font-size: 18px;
      font-weight: 400;
      line-height: 24.726px;
      width: 200px;
      white-space: normal;
      text-align: center;

      @media ${settingsStyled.m1200}{
        font-size: 16px;
        line-height: 21.726px;
      }

      @media ${settingsStyled.m959}{
        font-size: 16px;
        line-height: 21.726px;
      }

      @media ${settingsStyled.m767}{
        font-size: 16px;
        line-height: 21.726px;
        width: 100%;
        white-space: normal;
      }

      @media ${settingsStyled.m479}{
        font-size: 15px;
        line-height: 21.726px;
        
      }
  }

  &-active {
      .ant-tabs-tab-btn{
        color: #1C3B84 !important;
        font-weight: 600;
      }
  }

}

.ant-tabs-ink-bar {
  border-radius: 12px;
  background: #1C3B84;
}

.ant-tabs-content-holder {
  margin-top: 52px;
  margin-bottom: 112px;

  @media ${settingsStyled.m767} {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media ${settingsStyled.m479} {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ant-tabs-nav::before {
  border: none !important;
}

/* Ant Tab component bitiş */

.ant-form {
  width: 100%;
}

/*PopOver*/
.ant-popover{
&-inner {
  &-content {

    padding:0 ;
  }
}

}



/* FormSelectBox için yapıldı */
.ant-select-dropdown {
  padding: 10px 5px 10px 5px !important;
  z-index: 99999;
  .rc-virtual-list-holder {
    ${settingsStyled.scrollYBlue}

  }
  /* ant scroll iptal ediyor. */
  .rc-virtual-list-scrollbar {
    display: none;
  }

  border-radius: 6px;
  box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 0;
}

.ant-select-item-option {
  padding: 4px 14px !important;
  min-height: 29px !important;
  align-items: center;
  &-content {
    display: block;
    height: 100%;
    line-height: 1.44;
    font-weight: 500;
    font-size: 14px;
    font-weight: normal !important;
    color: #000;
    white-space: normal;
  }
}

/* FormTimePicker için yapıldı */
.ant-picker {
  &-time-panel-column {
    ${settingsStyled.scrollYBlue}

    width: 64px;
  }

  &-ranges {
    ${settingsStyled.displayCenter}

  }

  &-now {
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 24px;
    display: flex !important;
    align-items: center;
    padding: 0 5px;
    border-radius: 2px;

    &:hover {
      border: solid 1px #596b75;
    }

    &-btn {
      font-size: 14px;
      &:hover {
        color: #000;
      }
    }
  }

  &-ok {
    width: 100%;
    button {
      width: 100%;
      font-size: 15px;
      border: solid 1px rgba(0, 0, 0, 0.15);
      color: #000;
      background-color: #ffffff;
      &:focus {
        border: solid 1px #596b75;
        background-color: transparent;
      }

      &:hover {
        border: solid 1px #596b75;
        color: #000;
        background-color: transparent;
      }
      span {
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }
}

/*  date picker */
.ant-picker-today-btn {
  color: #000;

  &:hover {
    color: #000;
  }
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: "#002c5f" !important;
  }
}

.ant-picker-cell-today {
  .ant-picker-cell-inner::before {
    border-color: "#002c5f" !important;
  }
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

/* menü ayarları */

.ant-menu{
  background-color:transparent !important ;
  box-shadow:none;
  border-right: none;
  overflow: inherit !important;
}

/* dropdown */
.ant-dropdown {
  z-index: 99999;
  ul {
    border-radius: 4px;
  }

  &-menu {
    padding: 0;
  }
}


 /* ant form  failed */
.ant-form-item-explain {
  ${settingsStyled.displayCenter}

  div {
    color: #ff4d4f;
    font-size: 10px;
    font-weight: 500;
  }
}

.ant-form-item-has-error {
  .ant-input:hover,
  .ant-input:focus {
    box-shadow: none !important;
  }
}

/*  notification message */
.ant-notification {
  z-index: 99999;
}

.startModal {
  .ant-modal-body{
    background-color: #fff;
    border-radius:22px;
    padding: 0;
    padding-bottom:64px ;
  
  }
  .ant-modal-content{
    justify-content: center;
   //width: 1022px;
    //height: 1053px;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 22px;
  }  
}
/* confirm modal */
.confirmModal {
  width: 100%;

  &-container{
    text-align: center;
    line-height: 1.2;
    color: #416a7a;
  }

  .ant-btn {
    margin-right:0px !important ;
  }
  &-container {
    ${settingsStyled.displayColumn}
  }
  .ant-modal{
 
    &-close{
      top: 23px;
    right: 23px;
    width: 25px;
    height: 25px;
    &-x {
    
        ${settingsStyled.displayCenterCenter}
        width: 25px;
        height: 25px;
        .icon {
          width: 15px;
          height: 15px;
          
        }
      }
    }
    &-content{
     
    border-radius: 13px;
    max-width: 390px;

  }
  &-body {
 
    ${settingsStyled.displayFull}
    padding: 24px 24px 20px;
    @media (max-width: 1023px) {
      padding: 20px 20px 16px;
    }
  }
  &-confirm{
  
    &-body{
    ${settingsStyled.displayColumn}
    max-width:280px;
    span{
      svg{
    width:82px ;
    height:82px ;
       }
    }
    .anticon{
      margin-right:0px !important ;
    }
  }
  &-title{
  margin-top: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  text-align:center ;
  }
  &-btns{
    ${settingsStyled.displayCenterCenter}
    float: none;
   margin-bottom: 18px;
  }

  &-content{
    margin-left: 0;
  }
  }
  }
 
  .ant-modal-confirm-body-wrapper{
    margin-top:23px ;
  }
  .ant-btn {
    min-height: 48px;
    padding: 14px 19px;
    border-radius: 32px;
    border: none;
   
    
   
    &:nth-child(1){
      background-color: #f5f8f8 !important;
      margin-right:23px ;
      span{
        font-size: 16px;
        font-weight: 600;
        color: #a0b4bc;
      }
    }
    &:nth-child(2){
      background-color: #ff002b !important;
      span{
        font-size: 16px;
        font-weight: 600;
        color: #fff4f6;
      }
    }
  }

  &--success {
    .ant-modal-confirm-title {
      color: #4caf50;
    }
  }

  &--warning {
    .ant-modal-confirm-title {
      color: #faad14;
    }
  }

  &--error {
    .ant-modal-confirm-title {
      color: #ff4d4f;
    }
  }

  &--info {
    .ant-modal-confirm-title {
      color: #1890ff;
    }
  }

  &--confirm {
    .ant-modal-confirm-title {
      color: #000;
    }
    .ant-btn:nth-child(1) {
      background: #dd3c30;
      &:hover {
        background: #c42e24;
      }
    }
  }
}




/* form responsive */
/*
formResponsive-fullWidth
formResponsive-fullWidthColumn
formResponsive-btn
formResponsive-startAndEndTime
formResponsive-fullWidthCenter
formResponsive-double
formResponsive-triple
formResponsive-failed 
*/



.formResponsive {
  &-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .formSubmitButton {
      width: auto;
      margin: 0 10px;
    }
  }

  &-fullWidth {
    width: 100% !important;
    display: flex;
  }

  &-fullWidthColumn {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  &-fullWidthCenter {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-startAndEndTime {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .formTimePicker__datePicker {
      width: 100%;
    }
  }

  &-double {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .formTextInput,
    .formSelectBox,
    .formInputNumber,
    .formSwitch,
    .formCheckBox,
    .formTimePicker,
    .formDatePicker,
    .formDateAndTimePicker,
    .formRadioGroup,
    .formResponsive-startAndEndTime,
    & > .formMask {
      width: 48%;

      @media (max-width: 767px) {
        width:100% !important;
      }
    }
  }

  &-doubleCenter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .formTextInput,
    .formSelectBox,
    .formInputNumber,
    .formSwitch,
    .formCheckBox,
    .formTimePicker,
    .formDatePicker,
    .formDateAndTimePicker,
    .formRadioGroup,
    .formResponsive-startAndEndTime,
    & > .formMask {
      width: 48%;

      @media (max-width: 767px) {
        width:100%;

    }


    }
  }

  &-triple {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .formTextInput,
    .formSelectBox,
    .formInputNumber,
    .formSwitch,
    .formCheckBox,
    .formTimePicker,
    .formDatePicker,
    .formDateAndTimePicker,
    .formRadioGroup,
    .formResponsive-startAndEndTime,
    & > .formMask {
      width: 31%;
    }
  }
  &-failed {
    text-align: center;
    color: #ff4d4f;
    margin: 10px 0;
  }
}

/* //Genel cssler  */

.userPopOver{
  margin-top:10px !important;
  .ant-popover{
&-inner {
 border-radius:12px ;
}

&-arrow-content{
  background-color:#94244B ;
}
}  
}

/* Notification */
.notification{
  .ant-popover{
&-inner {
 border-radius:12px ;
}

&-arrow{
  right: 9px;
}
} 
}

.ant-menu:before{
  .ant-menu-submenu-popup{
    .headerNav{
  border:1px solid red !important; }
  }
  

}

.headerNav{
  background-color:#fff  !important;
  .layoutMainHeaderNav-menu-single__href{
    color:#00384d !important ;
  }
}


.newTitle {
 color: var(--Primary-Dark-Blue, #22325e);
 font-size: 36px;
 font-style: normal;
 font-weight: 600;
 line-height: 1.56; /* 155.556% */
 margin-bottom: 22px;
 text-align: center;

 @media ${settingsStyled.m1200} {
   font-size: 32px;
   line-height: 1.36;
   margin-bottom: 20px;
 }

 @media ${settingsStyled.m959} {
   font-size: 28px;
   line-height: 1.26;
   margin-bottom: 18x;
 }

 @media ${settingsStyled.m767} {
   font-size: 24px;
   line-height: 1.2;
   margin-bottom: 16px;
 }

 @media ${settingsStyled.m479} {
   font-size: 20px;
   line-height: 1.16;
   margin-bottom: 12px;
 }
}

.newSubTitle {
 color: #171a1f;
 font-size: 26px;
 font-style: normal;
 font-weight: 400;
 line-height: 1.41; /* 153.846% */
 text-align: center;

 @media ${settingsStyled.m1200} {
   font-size: 24px;
   line-height: 1.36;
 }

 @media ${settingsStyled.m959} {
   font-size: 20px;
   line-height: 1.26;
 }

 @media ${settingsStyled.m767} {
   font-size: 16px;
   line-height: 1.2;
 }

 @media ${settingsStyled.m479} {
   font-size: 14px;
   line-height: 1.16;
 }
}


`;
