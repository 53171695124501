import { useCallback } from "react";
import { useSelector } from "react-redux";
import Service from "../../../service";
import { translationData } from "../../../store/general/translationResourceSlice";

export default function getWordControl() {
  const { item } = useSelector(translationData);
  const GetWord = useCallback(
    (key) => {
      const translations = item;
      if (translations && key?.indexOf(" ") === -1) {
        const newKey = "Site." + key;
        const selectTranslations = translations[key.toLowerCase()];
        if (selectTranslations) {
          return selectTranslations;
        } else if (selectTranslations === "") {
          return newKey;
        }
        const requestData = {
          data: {
            name: newKey,
            description: newKey,
          },
        };
        const service = new Service();
        service.configuration.updateTranslation(requestData);
        return newKey;
      } else {
        return key;
      }
    },
    [item]
  );

  return { GetWord };
}
