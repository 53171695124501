import "antd/dist/antd.css";
import { GlobalStyles } from "../Assets/styled/globalStyles.styled";
import wrapper from "../store";
import LayoutMain from "../shared/layout/layoutMain";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import Router from "next/router";
import dynamic from "next/dynamic";

const Loading = dynamic(
  () => import("../shared/partnerComponents/loading/loading"),
  { ssr: false }
);

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Router.onRouteChangeStart = () => {
      setLoading(true);
    };
    // url verilebilir, default olarak url mevcut
    Router.onRouteChangeComplete = () => {
      setLoading(false);
    };
  });

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GlobalStyles /> {/* Global Stil için */}
      {loading && <Loading innerPageActive />}
      <LayoutMain>
        <Component {...pageProps} />
      </LayoutMain>
    </div>
  );
}

export default wrapper.withRedux(MyApp);
