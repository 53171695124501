import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { initialObjectState } from "../initialState";
import { contactFormModel } from "../../shared/models/mainComponents/contactFormModel";
import { contactPageModel } from "../../shared/models/mainComponents/contactPageModel";
import { selectBoxModel } from "../../shared/models/partnerComponents/selectBox";

const hydrate = createAction(HYDRATE);

const contactPageSlice = createSlice({
  name: "contact",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.contact,
      };
    });
  },
});

export const contactData = (state) => state.contact;
export const { setItemList, failed } = contactPageSlice.actions;

export default contactPageSlice.reducer;

export function getContactData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await Promise.all([
      service.content.getGetPage({ data: { code: "communication" } }),
      service.content.getSubjectContactForms({}),
      service.addressbook.getCountries({}),
    ]).then((res) => {
      let newData = {};
      Object.assign(
        newData,
        contactPageModel(res?.[0], ctx.userSettings.languageID),
        contactFormModel(res?.[1], ctx.userSettings.languageID),
        {
          countriesList: selectBoxModel(
            res?.[2]?.data,
            ctx.userSettings.languageID
          ),
        }
      );

      if (!newData.hasErrors) {
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(newData?.hasMessage));
      }
    });
  };
}
