import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { seoAPIModel } from "../../shared/models/partnerComponents/seoAPI";

const hydrate = createAction(HYDRATE);

const seoTagsSlice = createSlice({
  name: "seoTags",
  initialState: { items: [], loading: false, hasErrors: false, hasMessage: null },
  reducers: {
    setItemList: (state, { payload }) => {
      state.items = [...(payload?.items || [])];
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload || null;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        items: action.payload.seoTags?.items || [],
      };
    });
  },
});

export const seoTagsData = (state) => state.seoTags;

export const { setItemList, failed } = seoTagsSlice.actions;

export default seoTagsSlice.reducer;

export function getSeoTags(url, ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    try {
      const res = await service.content.getSEOTags({
        data: {
          url,
        },
      });
      if (res?.data && !res.hasFailed) {
        const seoData = seoAPIModel(res.data, url, ctx.userSettings.languageID);
        dispatch(setItemList(seoData || {}));
      } else {
        dispatch(failed(res?.messages?.[0]?.description || "An unknown error occurred"));
      }
    } catch (error) {
      dispatch(failed(error.message || "An error occurred"));
    }
  };
}
