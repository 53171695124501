import { createSlice, createAction } from "@reduxjs/toolkit";
import { initialSelectState } from "../initialState";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";

const hydrate = createAction(HYDRATE);

const headerMenuSlice = createSlice({
  name: "headerMenu",
  initialState: { ...initialSelectState, rootSubmenuKeys: [] },
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload.item || [];
      state.rootSubmenuKeys = payload.rootSubmenuKeys || [];
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload || null;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        item: action.payload.headerMenu?.item || [], 
        rootSubmenuKeys: action.payload.headerMenu?.rootSubmenuKeys || [],
      };
    });
  },
});

export const headerMenuData = (state) => state.headerMenu; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed } = headerMenuSlice.actions; // functions dışarıya aktarılması

export default headerMenuSlice.reducer;

export function getHeaderMenu(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    const menuRequestData = {
      data: {
        code: "headerMenu",
      },
    };
    await service.content.getMenu(menuRequestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(res.data));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
