import styled from "styled-components";
import settingsStyled from "../settings.styled";

export const SearchCustomStyled = styled.div`
  .searchCustom {
    position: absolute;
    z-index: 2;
    bottom: -94px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(20, 52, 126, 0.9);
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${settingsStyled.m1200} {
      padding: 0 20px;
    }

    @media ${settingsStyled.m767} {
      padding: 0 32px;
    }

    &-container {
      width: 100%;
      max-width: 1040px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      min-height: 100%;
      position: relative;
    }
    &--searchIcon {
      width: 28px;
      height: 28px;
      object-fit: contain;
      fill: #fff;
    }

    &-menu {
      position: absolute;
      top: 94px;
      width: 100%;
      height: auto;
      background-color: rgba(255, 255, 255, 0.97) !important;

      @media ${settingsStyled.m479} {
        justify-content: center;
      }
    }

    &-searchInputContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 21px;
      padding: 0 20px;
      width: 100%;
      height: 51px;
      border-radius: 12px;
      box-shadow: 0 -0.9px 3.5px 0 rgba(127, 194, 255, 0.25);
      border: solid 0.9px #ededed;
      background-color: rgba(255, 255, 255, 0.2);

      @media ${settingsStyled.m1200} {
        gap: 16px;
      }

      @media ${settingsStyled.m767} {
        gap: 12px;
      }

      @media ${settingsStyled.m479} {
        gap: 6px;
      }
    }

    &--searchInput {
      .ant-input-group,
      .ant-input-affix-wrapper,
      .ant-input {
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        color: #fff;

        &::placeholder {
          color: #fff;
        }

        .ant-input-suffix {
          svg {
            fill: white;
            width: 20px;
            height: 20px;
          }
        }
      }

      .ant-input-group-addon {
        display: none;
      }
    }
  }
`;
