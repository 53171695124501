import extendStyled from "./extend.styled";
import { media } from "./media.styled";
import { variablesStyled } from "./variables.styled";

const settingsStyled = {
  ...variablesStyled,
  ...media,
  ...extendStyled,
};

export default settingsStyled;
