import { GetI18n } from "../../utils/mixedControl";
import { productModel } from "./homePageModel";

export function productCategoryModel(requestData, languageID) {
  const data = requestData ? requestData.data : null;

  if (data && !requestData.hasFailed) {
    const {
      gallery,
      galleryID,
      headlines,
      id,
      products,
      showOnHomePage,
      slugURL,
      sortOrder,
      widgets,
    } = data;

    return {
      gallery: galleryModel(gallery, languageID),
      galleryID: galleryID !== undefined ? galleryID : null,
      headlines: headlinesModel(headlines, languageID),
      id: id !== undefined ? id : null,
      name: GetI18n(data, "name", languageID) || null,
      metaTags: GetI18n(data, "metaTags", languageID) || null,
      description: GetI18n(data, "description", languageID) || null,
      products: products ? productModel(products, languageID) : [],
      showOnHomePage: showOnHomePage !== undefined ? showOnHomePage : null,
      slugURL: slugURL !== undefined ? slugURL : null,
      sortOrder: sortOrder !== undefined ? sortOrder : null,
      widgets: widgets ? widgetsModel(widgets, languageID) : [],
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description || null,
    };
  }
}

function widgetsModel(data, languageID) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const {
        id,
        slugURL,
        sortOrder,
        code,
        contents,
        url,
        imagePath,
        productCategory,
        productCategoryID,
      } = item;

      return {
        title: GetI18n(item, "title", languageID) || null,
        description: GetI18n(item, "description", languageID) || null,
        id: id !== undefined ? id : null,
        slugURL: slugURL !== undefined ? slugURL : null,
        sortOrder: sortOrder !== undefined ? sortOrder : null,
        code: code !== undefined ? code : null,
        contents: contents ? contentsModel(contents, languageID) : [],
        url: url !== undefined ? url : null,
        imagePath: imagePath !== undefined ? imagePath : null,
        productCategory: productCategory !== undefined ? productCategory : null,
        productCategoryID: productCategoryID !== undefined ? productCategoryID : null,
      };
    });
  }
  return [];
}

function contentsModel(data, languageID) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const { sortOrder, id, code } = item;

      return {
        title: GetI18n(item, "title", languageID) || null,
        description: GetI18n(item, "description", languageID) || null,
        id: id !== undefined ? id : null,
        code: code !== undefined ? code : null,
        sortOrder: sortOrder !== undefined ? sortOrder : null,
      };
    });
  }
  return [];
}

function headlinesModel(data, languageID) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const {
        sortOrder,
        productCategoryID,
        id,
        imagePath,
        items,
        productCategory,
      } = item;

      return {
        title: GetI18n(item, "title", languageID) || null,
        subTitle: GetI18n(item, "subTitle", languageID) || null,
        id: id !== undefined ? id : null,
        imagePath: imagePath !== undefined ? imagePath : null,
        items: items !== undefined ? items : [],
        productCategory: productCategory !== undefined ? productCategory : null,
        sortOrder: sortOrder !== undefined ? sortOrder : null,
        productCategoryID: productCategoryID !== undefined ? productCategoryID : null,
      };
    });
  }
  return [];
}

function galleryModel(data, languageID) {
  if (data) {
    const { id, items } = data;
    return {
      id: id !== undefined ? id : null,
      items: items !== undefined ? items : [],
      title: GetI18n(data, "title", languageID) || null,
    };
  }
  return null;
}
