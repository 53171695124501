export const variablesStyled = {
  //font Color
  fontColorPrimary: "#00384d",
  errorRed: "#ff4d4f",
  successGreen: "#4caf50",
  warningYellow: "#faad14",
  infoBlue: "#1890ff",

  //icon color
  iconPrimary: "#000",

  //background color

  //background  images

  //border color

  //scroll color
  // scrollPrimary: "#ff002b",

  //font family
  fontFamilyPrimary: "Inter",

  //heigth width
  iconHeigthWidth: "24px",

  //font size
  fontSizePrimary: "14px",

  // form settings
  formFontColor: "#416a7a",
  formBg: "#fff",
  formBorder: "#E7E7E7",
  formBorderFocus: "#596b75",
  formFontSize: "14px",
  formRadius: "8px",
  formLabelColor: "#343434",
  formlabelFontSize: "18px",
  formHeight: "50px",
  formTextAreaHeight: "170px",
  formCheckBoxBg: "#e5ebec",
  formDatePickerselectBg: "#00384d",
  formRadioselectBg: "#00384d",
  formSwitchBg: "#00384d",
};
