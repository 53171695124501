import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const IconStyled = styled.div`
  ${settingsStyled.displayCenter};
  .icon {
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    color: ${settingsStyled.iconPrimary};
  }
`;
