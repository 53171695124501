import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";
import { initialObjectState } from "../initialState";
import { productCategoryTabBarModel } from "../../shared/models/mainComponents/productCategoryTabBarModel";
import { productCategoryModel } from "../../shared/models/mainComponents/productCategoryModel";
import { getLanguageID } from "../../shared/utils/common";
import { productDetailModel } from "../../shared/models/mainComponents/productDetailModel";

const hydrate = createAction(HYDRATE);

const productsPageSlice = createSlice({
  name: "products",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setItemCategoryList: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setIProductDetailCategoryList: (state, { payload }) => {
      state.productDetail = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setCatologId: (state, { payload }) => {
      state.catologId = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setProductId: (state, { payload }) => {
      state.productId = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.products,
      };
    });
  },
});

export const { setItemList, failed, setCatologId, setProductId ,setItemCategoryList, setIProductDetailCategoryList } = productsPageSlice.actions;

export const productsData = (state) => state.products.item;
export const selectCatologId = (state) => state.products.catologId;
export const categoryListData = (state) => state.products.data;
export const productDetailData = (state) => state.products.productDetail;

export default productsPageSlice.reducer;

export function getProductsData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    const productCategoryTabBarRequestData = {
      data: {
        code: "ProductCategoryTabBar",
        filterProductCategoryID: 1,
      },
    };
    try {
      const res = await service.content.getMenu(productCategoryTabBarRequestData);
      const newData = productCategoryTabBarModel(res, ctx.userSettings.languageID);
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    } catch (error) {
      console.error('getProductsData error:', error);
    }
  };
}

export function getProductCategoryData(ctx, _id) {
  return async (dispatch, getState) => {
    const service = new Service(ctx);
    const state = getState();
    const catologId = state.products.catologId;

    try {
      const response = await service.catalog.GetProductCategory({ id: _id ? _id : catologId });
      const newData = productCategoryModel(response, ctx.userSettings ?  ctx.userSettings.languageID : getLanguageID());
      if (response?.data && !response.hasFailed) {
        dispatch(setItemCategoryList(newData));
      } else {
        dispatch(failed(response?.messages?.[0]?.description));
      }
    } catch (error) {
      console.error('getProductCategoryData error:', error);
    }
  };
}

export function getProductDetailData(ctx) {
  return async (dispatch, getState) => {
    const service = new Service(ctx);
    const state = getState();
    const catologId = state.products.catologId;
    try {
      const response = await service.catalog.GetProduct({ id: ctx?.query?.productId });
      const newData = productDetailModel(response, ctx.userSettings.languageID);
      if (response?.data && !response.hasFailed) {
        dispatch(setIProductDetailCategoryList(newData));
      } else {
        dispatch(failed(response?.messages?.[0]?.description));
      }
    } catch (error) {
      console.error('getProductCategoryData error:', error);
    }
  };
}

