import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SearchResult from "../search/searchResult";
import SearchCustom from "./searchCustom";
import Service from "../../../service";
import { useRouter } from "next/router";
import { SearchCustomStyled } from "../../../styled/partialComponents/searchCustom.styled";
import useLocalStorage from "../useLocalStorage";
import { searchHeaderModel } from "../../models/mainComponents/searchHeaderModel";

//<Search
//  placeholder="enterSearchText"
//  type="colorWhiteBackgroundBlueTrasparent"
//  allowClear={true}
//  iconName="arena-search"
//  focusClassName="searchIndex--homeInlineFocus"
//  iconPositon="left"
//  alwaysResultsShow={true} // kayıtları her zaman göster
//  menuActive={true}
//  backBtnShow={true} //geri butonu göster
//  hideTitle={true} // başlığı gizle
// fristApiGetClose ={true} // ilk apiye gidişi kapatıyor
// openSearchControl={func}
///>

const Search = memo(
  ({
    type,
    placeholder,
    allowClear,
    enterButton,
    iconName,
    iconPositon,
    onChange,
    menuActive,
    className,
    focusClassName,
    alwaysResultsShow,
    backBtnShow = true,
    hideTitle = false,
    fristApiGetClose,
    openSearchControl,
    openSearch,
    setOpenSearch,
  }) => {
    const [searchItems, setSearchItems] = useState(null);
    const [langID, setLangID] = useLocalStorage("languageID", "");
    const { query } = useRouter();
    const [key, setKey] = useState(query?.key);
    const wrapperRef = useRef(null);

    // useEffect(() => {
    //   if (query.key) {
    //     handleOnChange(query.key);
    //   }
    //   console.log(query);
    // }, []);
    useEffect(() => {
      const hide = {
        opacity: 0,
        visibility: "hidden",
        transition: "all 0.5s ease",
      };

      const show = {
        opacity: 1,
        visibility: "initial",
      };

      if (wrapperRef.current) {
        // Show or hide search bar
        const styleToApply = openSearch ? show : hide;

        Object.keys(styleToApply).forEach((property) => {
          wrapperRef.current.style[property] = styleToApply[property];
        });
      }
    }, [openSearch]);

    const handleOnChange = useCallback((value) => {
      setKey(value);
      if (onChange) {
        onChange(value);
      }
      if (value?.length > 2) {
        const service = new Service();
        service.content
          .searchByWeb({
            data: { key: value, code: "HeaderSearch" },
          })
          .then((res) => {
            const newData = searchHeaderModel(res, value, langID);
            if (newData) {
              setSearchItems(newData);
            } else {
              setSearchItems(null);
            }
          });
      } else {
        setSearchItems(null);
      }
    }, []);

    const onSearch = useCallback((value) => {
      // push(getURL("search") + "?key=" + value);
    }, []);
    //console.log(searchItems);
    return (
      <SearchCustomStyled ref={wrapperRef}>
        <SearchCustom
          placeholder={placeholder}
          type={type}
          allowClear={allowClear}
          iconName={iconName}
          onChange={handleOnChange}
          enterButton={enterButton}
          onSearch={onSearch}
          className={className}
          focusClassName={focusClassName}
          iconPositon={iconPositon}
          menuActive={menuActive}
          alwaysResultsShow={alwaysResultsShow}
          backBtnShow={backBtnShow}
          defaultValue={key}
          searchItems={searchItems}
        >
          {searchItems && (
            <SearchResult
              popularItems={[]}
              searchItems={searchItems}
              hideTitle={hideTitle}
              value={key}
              openSearchControl={openSearchControl}
              totalDataCount={searchItems?.totalDataCount}
            />
          )}
        </SearchCustom>
      </SearchCustomStyled>
    );
  }
);

Search.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  iconPositon: PropTypes.string,
  allowClear: PropTypes.bool,
  menuActive: PropTypes.bool,
  enterButton: PropTypes.string,
  iconName: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
};

export default Search;
