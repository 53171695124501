import cookie from "cookie";

export function getCookiesServer(res, req, name) {
  if (res && req && name) {
    var cookies = cookie.parse(req.headers.cookie || "");

    return cookies[name];
  }
}

// Tarayıcı tarafında çerez oluşturmak için bir yardımcı fonksiyon
export function setCookie(name, value, options = {}) {
  // Çerezin süresini sonsuz yapmak için çok uzak bir tarih belirliyoruz
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 10); // 10 yıl

  const cookieString = cookie.serialize(name, value, {
    path: '/',
    expires, // Çerezin geçerlilik süresi
    ...options
  });

  // Çerezi tarayıcıya set etmek için `document.cookie` kullanıyoruz
  document.cookie = cookieString;
}

export function setCookiesServer(res, req, name, value, settings = {}) {
  const host = req?.headers?.host;
  let domain;

  if (host === "erseykurumsal.kartega.com") {
    domain = "kurumsalrouter.erseymakina.com";
  } else if (
    host === "erseymakina.com" ||
    host === "kurumsal.erseymakina.com" ||
    host === "www.erseymakina.com"
  ) {
    domain = "erseymakina.com";
  } else if (host === "localhost:3000") {
    domain = "localhost:3000";
  } else {
    domain = "kurumsalrouter.erseymakina.com";
  }

  const pro = process?.env?.NODE_ENV === "production";

  if (res && req && name) {
    res.setHeader(
      "Set-Cookie",
      cookie.serialize(name, value, {
        httpOnly: pro,
        secure: pro,
        domain,
        path: "/",
        ...settings,
      })
    );
  }
}
