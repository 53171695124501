// useBuildMenuTree.js

import { useCallback } from 'react';

const useBuildMenuTree = () => {
  const buildMenuTree = useCallback((items, parentId) => {
    const result = items
      .filter((item) => item?.parentMenuItemID === parentId)
      .map((item) => ({
        ...item,
        children: buildMenuTree(items, item.id),
      }));
    return result.length ? result : null;
  }, []);

  return buildMenuTree;
};

export default useBuildMenuTree;
