import { GetI18n } from "../../utils/mixedControl";
import { seoModel } from "../partnerComponents/seo";
import { widgetsModel } from "./aboutUsPageModel";

export function ourHistoryPageModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { code, widgets, id, headlines, slugURL, seoTag } = data;

    return {
      code,
      name: GetI18n(data, "name", languageID),
      widgets: widgetsModel(widgets, languageID),
      id,
      headlines: headlinesModel(headlines, languageID),
      slugURL,
      seoTag: seoModel(seoTag, languageID),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

export const headlinesModel = (data, languageID) => {
  return data?.map((item) => {
    return {
      title: GetI18n(item, "title", languageID),
      subTitle: GetI18n(item, "subTitle", languageID),
    };
  });
};
