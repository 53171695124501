import styled from "styled-components";
import settingsStyled from "../../../../styled/settings.styled";

export const LayoutMainFooterStyled = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 -6px 19.1px -5px rgba(192, 192, 192, 0.25);
  .layoutMainFooter {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-content {
        /* 
        @media ${settingsStyled.m767} {
          padding: 72px 0px 0 0px; Üstten 72px, soldan 300px 
        }*/
        @media ${settingsStyled.m719} {
          padding: 0; /* Üstten 72px, soldan 300px */
          margin: 0 20px;
        }
        @media ${settingsStyled.m479} {
          padding: 0; /* Üstten 72px, soldan 300px */
          margin: 0 20px;
        }
      }
      &-rectangle {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        margin-top: 100px;
        @media ${settingsStyled.m479} {
          height: 70px;
        }
        position: relative;
        &-powered {
          position: absolute;
          z-index: 2;
          top: 55%;
          right: 7%;
          flex-grow: 0;
          margin: 6px 0 0;
          opacity: 0.8;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          color: #fff;
          cursor: pointer;
          @media ${settingsStyled.m479} {
            font-size: 7.4px;
            top: 63%;
          }
          sup {
            font-size: 8px;
            color: #fff;
            @media ${settingsStyled.m479} {
              font-size: 4.2px;
            }
          }
        }
        &-clip {
          height: 100%;
          display: flex;
          flex-direction: row;

          &-left {
            flex: 1;
            width: 100%;
            background-color: #1c3b84;
            clip-path: polygon(0 0, 100% 81%, 100% 100%, 0% 100%);
            @media ${settingsStyled.m479} {
              padding-left: 15px;
            }
          }
          &-right {
            flex: 1;
            width: 100%;
            background-color: #1c3b84;
            clip-path: polygon(0 81%, 100% 0, 100% 100%, 0% 100%);
            padding-right: 12px; /* veya padding-right: 5px; */
          }
        }
        &-copyRight {
          p {
            text-align: center;
            color: #cdcdcd;
            font-family: "Poppins", sans-serif;
            font-display: swap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            @media ${settingsStyled.m479} {
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.2;
            }
          }
        }
      }
    }
  }
`;

export const FooterContentStyled = styled.div`
  width: 100%;
  display: flex;
  margin-top: 70px;
  @media ${settingsStyled.m719} {
    display: inline-block;
  }
  .footerContent {
    &-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      flex-direction: row;
      gap: 10px;
      @media ${settingsStyled.m1279} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        padding-left: 20px;
        @media ${settingsStyled.m719} {
          padding-left: 0px;
        }
        &-FooterImageMenu,
        &-FooterCorporateMenu {
          width: 100% !important;
          margin-left: 0 !important;
        }
      }
      @media ${settingsStyled.m719} {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(1, 1fr);
        &-FooterCorporateMenu {
          margin-right: 0 !important;
        }
      }
      &-FooterImageMenu {
        width: 275px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 22px;
        margin-bottom: 20px;
        &-imageMenu {
          display: flex;
          flex-direction: row;
          &-icon {
            flex: 1;
            margin-right: 14px;
            & > div > svg {
              fill: #365097;
              width: 24px;
              height: 24px;
            }
          }
          &-content {
            flex: 11;
            &-map {
              &:hover {
                color: #365097;
                cursor: pointer;
              }
              color: var(--Primary-Light-Blue, #365097) !important;
              font-family: Inter;
              font-display: swap;
              // line-height: 22px; /* 157.143% */
              text-decoration-line: underline;
            }
            p {
              max-width: 228px;
              color: #343434;
              font-size: 14px;
              font-weight: 500;
              line-height: 23.5px; /* 167.857% */

              @media ${settingsStyled.m719} {
                max-width: 100%;
              }
            }
          }
        }
      }
      &-FooterCorporateMenu {
        &-title {
          width: 100%;
          flex-grow: 0;
          margin: 0 40px 28px 0;
          font-family: Inter;
          font-display: swap;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #1c3b84;
        }
        &-subTitle {
          flex-grow: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 16px 0 0;
          padding: 0;
          p {
            flex-grow: 0;
            font-family: Inter;
            font-display: swap;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #343434;
            &:hover {
              color: #365097;
              cursor: pointer;
            }
          }
        }
        &-social {
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-top: 30px;
          @media ${settingsStyled.m479} {
            margin-top: 0;
          }
          &:hover {
            cursor: pointer;
          }
          & > div > svg {
            fill: #365097;
            width: 24px;
            height: 24px;
          }
        }
      }

      &-menu {
        ul > li:first-child {
          .ant-menu-submenu-title {
            border-radius: 12px;
            box-shadow: 0 1px 1.6px 1px rgba(139, 139, 139, 0.25);
            background-color: #fff;
            height: 46px;
            width: 342px;
            margin-left: 24px;
            @media ${settingsStyled.m719} {
              width: 100%;
              margin-left: 0;
            }
            @media ${settingsStyled.m479} {
              width: auto;
            }
          }
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background-color: transparent;
          span {
            color: #1c3b84;
          }
        }
        .ant-menu-inline {
          @media ${settingsStyled.m719} {
            border: none;
          }
        }
        .ant-menu-inline .ant-menu-item::after {
          border-right: 3px solid #1c3b84;
        }
        @media ${settingsStyled.m719} {
          max-width: 620px;
        }
      }
    }
  }
`;
