import React, { useCallback, useState } from "react";
import { Menu } from "antd";
import useBuildMenuTree from "../../shared/utils/hooks/useBuildMenuTree";
import { HeaderMobileLeftMenuStyled } from "../../shared/layout/layoutMain/styled/headerContentStyled.styled";
import { useRouter } from "next/dist/client/router";
import Icon from "../../shared/partnerComponents/icons/icon";
import useIconName from "../../shared/utils/hooks/useIconName";
import { LanguagesMenuStyled } from "../../shared/layout/layoutMain/styled/layoutMainHeaderStyled.styled";

const HeaderMobileLeftMenu = ({
  selectedLanguage,
  firstData,
  onCloseDrawer,
  handleLanguageChange,
  languages,
}) => {
  const router = useRouter();
  const [isSelected, setIsSelected] = useState(null);

  const handleGotoRoute = useCallback(
    (data) => {
      if (!data?.subMenuItems?.length) {
        router.push(data?.url)
        onCloseDrawer();
      }
    },
    [firstData]
  );
  //console.log(isSelected);
  const handleMouseOver = useCallback(
    (data) => {
      setIsSelected(data);
    },
    [firstData]
  );

  const handlelastChildRoute = useCallback(
    (data) => {
      if (!data?.subMenuItems?.length) {
        const productId = data?.productID || "";
        if (productId && data.url) {
          router
            .push({
              pathname: data.url,
              query: { productId },
            })
        }
        onCloseDrawer();
      }
    },
    [firstData]
  );
  const handleProductGotoRoute = useCallback(
    (data) => {
      router.push(data?.url)
      onCloseDrawer();
    },
    [firstData]
  );

  return (
    <>
      <div
        style={{
          display: "block",
          width: "100%",
        }}
      >
        {firstData &&
          firstData?.map((data, index) => {
            const iconName = useIconName(data?.code);
            return (
              <HeaderMobileLeftMenuStyled
                isSelected={isSelected === data}
                className="headerMobile"
                key={index}
              >
                <Menu mode="inline" key={index}>
                  <Menu.SubMenu
                    id="parent"
                    onTitleMouseEnter={() => handleMouseOver(data)}
                    onTitleClick={() => handleGotoRoute(data)}
                    key={data.sortOrder}
                    title={data.name}
                    icon={
                      <Icon
                        className={"itemStyled-icons-icon"}
                        name={`icon-${iconName}`}
                      />
                    }
                  >
                    {data?.subMenuItems &&
                      data?.subMenuItems.map((child, index) => (
                        <div key={index}>
                          <Menu.SubMenu
                            id="child"
                            title={
                              <p
                                style={{ width: "100px" }}
                                onClick={() => handleProductGotoRoute(child)}
                              >
                                {child.name}
                              </p>
                            }
                            //   onTitleClick={() => handleGotoRoute(child)}
                            key={index}
                            children
                          >
                            {child?.subMenuItems != [] &&
                              child?.subMenuItems.map((item, index) => (
                                <Menu.Item
                                  onClick={() => {
                                    handlelastChildRoute(item);
                                  }}
                                  key={index}
                                >
                                  {item.name}
                                </Menu.Item>
                              ))}
                          </Menu.SubMenu>
                        </div>
                      ))}
                  </Menu.SubMenu>
                </Menu>
              </HeaderMobileLeftMenuStyled>
            );
          })}

        <LanguagesMenuStyled className="languages">
          <div className="languages-container">
            <Menu
              mode="inline" //key={index}
            >
              <Menu.SubMenu
                className="headerMobile-parent-lang"
                title={selectedLanguage ? selectedLanguage : "Dil"}
                onClick={({ key }) => handleLanguageChange(key)}
                //ey={index}
                icon={
                  <Icon
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "10px",
                    }}
                    className={"itemStyled-icons-icon"}
                    name={`icon-${selectedLanguage?.toLowerCase()}`}
                  />
                }
                children
              >
                {languages &&
                  languages?.map((lang) => {
                    const { code, id, name } = lang || {};
                    return (
                      <Menu.Item
                        icon={
                          <Icon
                            style={{
                              height: "24px",
                              width: "24px",
                              marginRight: "10px",
                            }}
                            className={"itemStyled-icons-icon"}
                            name={`icon-${code?.toLowerCase()}`}
                          />
                        }
                        key={id}
                      >
                        {name}
                      </Menu.Item>
                    );
                  })}
              </Menu.SubMenu>
            </Menu>
          </div>
        </LanguagesMenuStyled>
      </div>
    </>
  );
};

export default HeaderMobileLeftMenu;
