import baseService from "./baseService";

export default class AddressBookService extends baseService {
  constructor(service) {
    super(service);
    this.schemaName = "addressbook";
  }

  getCountries(entity) {
    return this.postObject(this.schemaName, "GetCountries", entity);
  }

  getCities(entity) {
    return this.postObject(this.schemaName, "GetCities", entity);
  }

  getCounties(entity) {
    return this.postObject(this.schemaName, "GetCounties", entity);
  }

  getNeighborhoods(entity) {
    return this.postObject(this.schemaName, "GetNeighborhoods", entity);
  }
}
