import { memo } from "react";
import PropTypes from "prop-types";

//<CustomLink href={url} alt={alt} key={index} style={styles}>
//  <img src={logo} alt={alt} />
//</CustomLink>

const CustomLink = memo(
  ({ target, href, alt, children, className, style, onClick }) => {
    return href ? (
      <a href={href} alt={alt}>
        <div
          className={className}
          style={style}
          target={target}
          onClick={onClick}
        >
          {children}
        </div>
      </a>
    ) : (
      <span style={{ color: "red" }}>url undefined</span>
    );
  }
);

CustomLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.any,
  alt: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
};

export default CustomLink;
