import React, { memo } from "react";
import { FormSubmitButtonStyled } from "../formElement/styled/formSubmitButton.styled";
import { Button } from "antd";
import Loading from "../loading/loading";
import PropTypes from "prop-types";
import Icon from "../icons/icon";
import getWordControl from "../../utils/language/getWordControl";

// <ButtonCustom
//  label="lblDatePicker"
//  className=""
//  disabled={false}
//  loadingActive={true}
//  textColor=""
//  backgroundColor=""
//  btnType="redBtn" / "angularBlue"
//  onClick={() => console.log("1")}
//  style={{ width: 200 }}
//  width={500}
//  widthAutoActive={true} // full width kapatıyor
//  iconPosition="left" /"right"
//  iconName=""
//  labelGetwordClose={false}
// />

const ButtonCustom = memo(
  ({
    label = "",
    backgroundColor,
    className,
    btnType,
    loadingActive,
    textColor,
    disabled,
    onClick,
    style,
    width,
    iconPosition,
    iconName,
    widthAutoActive,
    labelGetwordClose,
  }) => {
    const { GetWord } = getWordControl();

    return (
      <FormSubmitButtonStyled
        className={
          "formSubmitButton " +
          (widthAutoActive ? "formSubmitButton--fullWidth " : "") +
          (className || "")
        }
        style={{ width }}
      >
        <Button
          type="primary"
          htmlType="button"
          style={{ backgroundColor, ...style }}
          disabled={loadingActive || disabled}
          onClick={onClick}
          className={"ant-" + btnType}
        >
          {iconPosition === "left" && iconName && (
            <Icon
              name={iconName}
              className="formSubmitButton--iconLeft"
              classNameOut="formSubmitButton-iconOut"
            />
          )}

          {
            label && (
              <span style={{ color: textColor }}>
                {labelGetwordClose ? label : GetWord(label)}
              </span>
            )
          }

       

          {iconPosition === "right" && iconName && (
            <Icon
              name={iconName}
              className="formSubmitButton--iconRight"
              classNameOut="formSubmitButton-iconOut"
            />
          )}
        </Button>
        {loadingActive && <Loading innerPageActive={true} size="small" />}
      </FormSubmitButtonStyled>
    );
  }
);

ButtonCustom.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  loadingActive: PropTypes.bool,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  btnColor: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.node,
};

export default ButtonCustom;
