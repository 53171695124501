import { createSlice, createAction } from "@reduxjs/toolkit";
import { initialObjectState } from "../initialState";
import { HYDRATE } from "next-redux-wrapper";
import Service from "../../service";

const hydrate = createAction(HYDRATE);

const initAppSlice = createSlice({
  name: "initApp",
  initialState: { ...initialObjectState, isLoggedIn: false },
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload.data;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
      state.isLoggedIn = payload.data.isLoggedIn;
      state.token = payload.token; //auth içerisinde lazım
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
      state.token = null;
    },
    isLoggedInControl: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.initApp,
      };
    });
  },
});

export const initAppData = (state) => state.initApp; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed, isLoggedInControl } = initAppSlice.actions; // functions dışarıya aktarılması

export default initAppSlice.reducer;

export function getInitApp(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.configuration.initApp({}).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(res));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
