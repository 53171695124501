import styled from "styled-components";

export const HeaderContentSytled = styled.div`
  .headerContent {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14.636px;

      &-item {
        display: flex;
        padding: 5.728px 9.336px 5.636px 7.318px;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        border-radius: 12px;
        width: 253px;
        height: 50px;
      }
    }
  }
`;

export const ItemStyled = styled.div`
  padding: 5.728px 9.336px 5.636px 7.318px;
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 12px;
  width: 253px;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .itemStyled {
    &-icons {
      margin-left: 7px;
      & > div > svg {
        fill: ${(props) => (props.isSelected ? " #FFFFFF" : "#A3A3A3")};
        width: 24px;
        height: 24px;
      }
    }
  }
  background-color: ${(props) =>
    props.isSelected ? " #1C3B84" : "transparent"};
  color: ${(props) => (props.isSelected ? "white" : "black")};
  p {
    color: ${(props) => (props.isSelected ? "white" : "#9095A0")};
    font-family: Inter;
    font-display: swap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.954px; /* 156.812% */
    letter-spacing: 0.14px;
  }
  &:hover {
    background-color: ${(props) =>
      props.isSelected ? "#1C3B84" : "transparent"};
  }
`;

export const MenuTreeStyled = styled.div`
  // Diğer stiller buraya eklenebilir
  // ...
  .menuTree {
    box-shadow: 0px 0px 15px rgb(168 165 165 / 38%);
    &-container {
      margin-top: 52px;
      margin-left: 22px;
      &-title {
        display: flex;
        align-items: center; /* Bu satır, içindeki öğeleri dikey hizalar */
        gap: 8px;
        & > div > svg {
          fill: #365097;
          width: 24px;
          height: 24px;
        }
        p {
          color: var(--Primary-Blue, #1c3b84);
          font-family: Inter;
          font-display: swap;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 21.954px; /* 156.812% */
          letter-spacing: 0.14px;
        }
      }
    }
  }
  .menuContent {
    // Your styles for the content div
    display: flex;
    flex-direction: row;
    margin-top: 31px;
    align-items: flex-start;
    gap: 14.636px;
  }

  .leftPanel {
    // Your styles for the left panel
    flex: 1;

    &-name {
      display: flex;
      align-items: center; /* Align items vertically in the center */
      width: 237px;
      height: 50px;
      justify-content: space-between;
      & > div > svg {
        fill: #365097;
        width: 24px;
        height: 24px;
      }
      p {
        color: var(--Primary-Blue, #9095a0);
        font-family: Inter;
        font-display: swap;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.954px; /* 156.812% */
        letter-spacing: 0.14px;
        margin-right: 8px; /* Add some margin between p and ArrowRightOutlined */
      }

      /* Adjust the styling for ArrowRightOutlined if needed */
      .anticon-arrow-right {
        // Your styles for ArrowRightOutlined
        font-size: 16px;
      }
    }
  }

  .rightPanel {
    // Your styles for the right panel
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 14.64px;
  }

  .rightPanelItem {
    cursor: pointer;
    padding: 7.093px 43.364px 6.46px 15px;
    align-items: center;
    p {
      color: var(--Primary-Blue, #9095a0);
      font-family: Inter;
      font-display: swap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21.954px; /* 156.812% */
      letter-spacing: 0.14px;
      &:hover {
        color: #1c3b84;
      }
    }
  }
`;

export const HeaderMobileLeftMenuStyled = styled.div`
  .ant-menu-submenu-title {
    height: 50px !important;
    & > div > svg {
      fill: ${(props) => (props.isSelected ? " #fff" : "#1C3B84")};
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    span {
      color: #1c3b84;
    }
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #1c3b84;
  }
  #parent {
    .ant-menu-submenu-title {
      margin-bottom: 10px;
      span {
        color: ${(props) => (props.isSelected ? " #fff" : "#000")};
      }
      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow {
        color: ${(props) => (props.isSelected ? " #fff" : "#000")};
      }
      background-color: ${(props) => (props.isSelected ? " #1C3B84" : "#fff")};
      border-radius: 12px;
      box-shadow: 0 1px 1.6px 1px rgba(139, 139, 139, 0.25);
    }
    #child {
      .ant-menu-submenu-title {
        background-color: white;
        border: none;
        box-shadow: none;
        .ant-menu-submenu-arrow {
          color: #1c3b84;
        }
      }
    }
  }
`;
