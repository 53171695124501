import baseService from "./baseService";

export default class ContentService extends baseService {
  constructor(service) {
    super(service);
    this.schemaName = "content";
  }
  GetFooterModel(entity) {
    return this.postObject(this.schemaName, "GetFooterModel", entity);
  }

  getMenu(entity) {
    return this.postObject(this.schemaName, "GetMenu", entity);
  }
  GetAllMenuItems(entity) {
    return this.postObject(this.schemaName, "GetAllMenuItems", entity);
  }
  getSEOTags(entity) {
    return this.postObject(this.schemaName, "GetSEOTags", entity);
  }
  getAnnouncements(entity) {
    return this.postObject(this.schemaName, "GetAnnouncements", entity);
  }
  getGetPage(entity) {
    return this.postObject(this.schemaName, "GetPage", entity);
  }

  getSubjectContactForms(entity) {
    return this.postObject(this.schemaName, "GetSubjectContactForms", entity);
  }

  searchByWeb(entity) {
    return this.postObject(this.schemaName, "SearchByWeb", entity);
  }

  getDocumentModel(entity) {
    return this.postObject(this.schemaName, "GetDocumentModel", entity);
  }

  getVideoModel(entity) {
    return this.postObject(this.schemaName, "GetVideoModel", entity);
  }

  updateContactForm(entity) {
    return this.postObject(this.schemaName, "UpdateContactForm", entity);
  }
}
