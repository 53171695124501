// @media ${settingsStyled.m359} {
// }

export const media = {
  m320: `(max-width: 320px)`,
  m359: `(max-width: 359px)`,
  m479: `(max-width: 479px)`,
  m587: `(max-width: 587px)`,
  m719: `(max-width: 719px)`,
  m767: `(max-width: 767px)`,
  m959: `(max-width: 959px)`,
  m1023: `(max-width: 1023px)`,
  m1200: `(max-width: 1200px)`,
  m1279: `(max-width: 1279px)`,
  m1365: `(max-width: 1365px)`,
  m1440: `(max-width: 1440px)`,
  m1600: `(max-width: 1600px)`,
  m1920: `(max-width: 1920px)`,
};
