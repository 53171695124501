import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import Service from "../../service";
import { layoutMainFooterModel } from "../../shared/models/layout/layoutMainFooterModel";
import { cleanData } from "../../shared/auth";

const hydrate = createAction(HYDRATE);

const footerSlice = createSlice({
  name: "footer",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = cleanData(payload); // Temizlenmiş veriyi kullan
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.footer,
      };
    });
  },
});

export const footerData = (state) => state.footer;
export const { setItemList, failed } = footerSlice.actions;

export default footerSlice.reducer;

export function getFooterData(ctx) {
  return async (dispatch) => {
    const service = new Service(ctx);
    await service.content.GetFooterModel({}).then((res) => {
      const newData = layoutMainFooterModel(res, ctx.userSettings.languageID);

      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(cleanData(newData))); // Temizlenmiş veriyi kullan
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
