import styled from "@emotion/styled";
import settingsStyled from "../../../../styled/settings.styled";

export const LayoutMainHeaderStyled = styled.nav`
  width: 100%;
  height: 105px;
  flex-shrink: 0;
  background-color: #1c3b84;
  box-shadow: 0 4px 4px 0 #112b69;
  display: flex;
  position: relative;
  z-index: 3;

  @media ${settingsStyled.m1200} {
    height: 88px;
  }

  .ant-drawer-header {
    width: fit-content;
  }
  .ant-drawer-left > .ant-drawer-content-wrapper {
    box-shadow: none;
  }
  .layoutMainHeader-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    @media ${settingsStyled.m1023} {
      padding: 0;
    }
  }

  .layoutMainHeader-container-logo {
    color: #ededed;
    flex-shrink: 0;
    & > div > svg {
      fill: #365097;
      width: 200px;
      height: 55.968px;

      @media ${settingsStyled.m1200} {
        width: 180px;
      }

      @media ${settingsStyled.m1023} {
        width: 160px;
      }

      @media ${settingsStyled.m479} {
        width: 132px;
      }
    }
  }

  .layoutMainHeader-container-content {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 50px;

    @media ${settingsStyled.m1023} {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      padding: 0 24px 0 2px;
      gap: 0;
    }
    .ant-btn {
      @media ${settingsStyled.m479} {
        padding: 4px 10px !important;
      }
    }
    &-search {
      & > div > svg {
        fill: #fff;
        width: 32px;
        height: 32px;

        @media ${settingsStyled.m1200} {
          width: 30px;
          height: 30px;
        }

        @media ${settingsStyled.m1023} {
          width: 28px;
          height: 28px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-lang {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: transparent;
      color: white; /* Yazı rengini beyaz yapar */
      border: none;
      cursor: pointer;
      font-size: 22px;
      @media ${settingsStyled.m719} {
        margin-right: 0px;
      }
      @media ${settingsStyled.m479} {
        font-size: 15px;
      }

      & > div > svg {
        fill: #fff;
        width: 32px;
        height: 32px;
      }

      option {
        color: black;
      }
    }
    &-leftNav {
      margin-top: 16px;
      button {
        border: none;
        outline: none;
        box-shadow: none;
        & > div > svg {
          fill: #fff;
          width: 32px;
          height: 32px;

          @media ${settingsStyled.m1200} {
            width: 30px;
            height: 30px;
          }

          @media ${settingsStyled.m1023} {
            width: 28px;
            height: 28px;
          }
        }
      }
      /* İstediğiniz ikon kitini kullanarak üç çizgi simgesini ekleyin */
      &:before {
        //content: "\u2261"; /* Bu, Unicode'da üç çizgi simgesini temsil eder, gerektiğinde ikon kitinize göre değiştirin */
        font-size: 46px; /* İkonun boyutunu ayarlayın */
        color: #ededed; /* İkon rengini ayarlayın */
      }
    }
  }
`;

export const LanguagesMenuStyled = styled.div`
  //position: absolute;
  //bottom: 0;
  width: 320px;
  margin-top: 20px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  .languages-container {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #f7f7f7;
    border-radius: 8px;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-submenu-title {
    width: 155px;
    text-align: center;
  }
`;
