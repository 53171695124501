import { GetI18n } from "../../utils/mixedControl";

export function catalogsPageModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;
  if (data && !requestData.hasFailed) {
    const { productCategoryID, productCategoryMenuItems } = data;
    return {
      name: GetI18n(data, "name", languageID),
      headlines: headlinesModel(data?.headlines, languageID),
      productCategoryID: productCategoryID ? productCategoryID : null,
      productCategoryMenuItems: productCategoryMenuItems ? menuItemsModel(
        productCategoryMenuItems,
        languageID
      ): null,
      productDocuments: documentsModel(data?.productDocuments, languageID),
      eNewsLetterDocuments: data?.eNewsLetterDocuments ?  documentsModel(
        data?.eNewsLetterDocuments,
        languageID
      ): null,
      companyDocuments: data?.companyDocuments ? documentsModel(data?.companyDocuments, languageID) : null,
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function headlinesModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        title: GetI18n(item, "title", languageID),
      };
    });
  }
  return newData;
}

const documentsModel = (data, languageID) => {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      const { productCategoryID, imagePath, productCategory, filePath } = item;
      return {
        title: GetI18n(item, "title", languageID),
        imagePath: imagePath ? imagePath : null,
        filePath: filePath ? filePath : null,
        productCategory: productCategory ? productCategory : null,
        productCategoryID: productCategoryID ? productCategoryID : null,
      };
    });
    return newData;
  }
};

function menuItemsModel(data, languageID) {
  let newData = [];
  if (data?.length > 0) {
    newData = data?.map((item) => {
      return {
        name: GetI18n(item, "name", languageID),
        productCategoryID: item?.productCategoryID ? item?.productCategoryID : null,
        id: item?.id ? item?.id : null,
        webImagePath: item?.webImagePath ? item?.webImagePath : null,
        mobileImagePath: item?.mobileImagePath ? item?.mobileImagePath : null,
        url: item?.url ? item?.url : null,
      };
    });
  }
  return newData;
}
