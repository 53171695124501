import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import Icon from "../icons/icon";
import { Menu } from "antd";
import getWordControl from "../../utils/language/getWordControl";

//<SearchCustom
//placeholder="enterSearchText"
//type="colorWhiteBackgroundBlueTrasparent" // "blue"
//allowClear={true}
//enterButton="Şimdi Başla"
//iconName="leafexsearch-2-line"
//onSearch={onSearch}
//iconPositon="right" / "left"
//className="homeInline"
//menuActive= {true} // aşağı açılır menuyu aktive ediyor
//focusClassName="homeInlineFocus"
//alwaysResultsShow={true} //arama sonuçlarını her zaman göster
//backBtnShow={true}
// defaultValue=""
//componentActiveOutFunc={func}
///>

// const onSearch = useCallback((value) => {
//   console.log(value);
// }, []);
let timeout;

const SearchCustom = memo(
  ({
    type,
    placeholder = "enterSearchText",
    allowClear = true,
    enterButton,
    onSearch,
    iconName = "arena-search",
    iconPositon,
    onChange,
    menuActive,
    children,
    className,
    focusClassName,
    alwaysResultsShow,
    backBtnShow,
    defaultValue,
    searchItems,
    value,
    setValue,
    componentActiveOutFunc,
  }) => {
    const { GetWord } = getWordControl();
    const { Search } = Input;
    const [menuFocus, setMenuFocus] = useState(false);
    const [menuHover, setMenuHover] = useState(false);

    useEffect(() => {
      if (menuHover && componentActiveOutFunc) {
        componentActiveOutFunc();
      }
    }, [menuHover]);

    const onFocus = useCallback(() => {
      if (menuActive) {
        setMenuFocus((prev) => !prev);
      }
    }, []);

    const onBlur = useCallback((e) => {
      e.nativeEvent.relatedTarget?.click();
      if (menuActive) {
        setMenuFocus((prev) => !prev);
      }
    }, []);

    function debounce(callback, wait) {
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          callback.apply(this, args);
        }, wait);
      };
    }

    return (
      <div
        onMouseLeave={() => setMenuHover(false)}
        onMouseEnter={() => setMenuHover(true)}
        className={
          "searchCustom " +
          (className || "") +
          (focusClassName && ((menuFocus && menuHover) || alwaysResultsShow)
            ? focusClassName
            : "") +
          (" searchCustom--" + type)
        }
      >
        <div className="searchCustom-container">
          <div className="searchCustom-searchInputContainer">
            {iconPositon === "left" && (
              <Icon name={iconName} className="searchCustom--searchIcon" />
            )}

            <Search
              placeholder={placeholder ? GetWord(placeholder) : ""}
              allowClear={allowClear}
              enterButton={enterButton}
              onSearch={onSearch && onSearch}
              onChange={debounce((e) => {
                onChange ? onChange(e.target.value) : null;
              }, 1000)}
              onFocus={onFocus}
              onBlur={onBlur}
              className="searchCustom--searchInput"
              defaultValue={defaultValue}
            />
            {iconPositon === "right" && (
              <Icon name={iconName} className="searchCustom--rightIcon" />
            )}
          </div>
        </div>
        {((menuFocus && menuHover && searchItems) || alwaysResultsShow) && (
          <Menu
            selectedKeys={["menu"]}
            mode="horizontal"
            className="searchCustom-menu"
          >
            {children}
          </Menu>
        )}
      </div>
    );
  }
);

SearchCustom.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  iconPositon: PropTypes.string,
  allowClear: PropTypes.bool,
  menuActive: PropTypes.bool,
  enterButton: PropTypes.string,
  iconName: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
};

export default SearchCustom;
