import { GetI18n, imagesReplace } from "../../utils/mixedControl";

export function searchHeaderModel(requestData, searchKey, languageID) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      ...data,
      item: searchAllItems(data, searchKey, languageID),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

export function searchAllItems(data, searchKey, languageID) {
  const newData = [];
  const cardList = [
    {
      code: "Announcements",
      type: "announcements",
      getWordKey: "Announcement",
    },
    { code: "Categories", type: "categories", getWordKey: "Category" },
    { code: "Documents", type: "documents", getWordKey: "Document" },
    { code: "Pages", type: "pages", getWordKey: "Page" },
    {
      code: "Products",
      type: "products",
      getWordKey: "Product",
    },
    { code: "Solutions", type: "solutions", getWordKey: "Solution" },
    { code: "Videos", type: "videos", getWordKey: "Video" },
  ];

  for (let i = 0; i < cardList.length; i++) {
    const cardSettings = cardList[i];
    const selectList = data[cardSettings?.type];

    if (selectList?.length > 0 && data) {
      selectList.forEach((element) => {
        const { imagePath, url, id, title, solutionCategoryID } = element || {};

        let subTitle = title
          ? GetI18n(element, "title", languageID)
          : GetI18n(element, "name", languageID);

        const selectIndex = subTitle
          ?.toLocaleLowerCase()
          ?.indexOf(searchKey?.toLocaleLowerCase());

        // kelime arıyor ve bold yapıyor
        if (selectIndex !== -1 && subTitle) {
          subTitle =
            subTitle.substring(0, selectIndex) +
            `<span style="font-weight:bold">${subTitle?.substring(
              selectIndex,
              selectIndex + searchKey.length
            )}</span>` +
            subTitle.substring(selectIndex + searchKey.length);
        }

        //url ayarlıyor
        let link = null;

        if (cardSettings?.code === "Products") {
          link = GetI18n(element, "url", languageID) + "?productId=" + id;
        } else if (cardSettings?.code === "Solutions") {
          link =
            GetI18n(element, "url", languageID) +
            "?solutionCategoryId=" +
            solutionCategoryID;
        } else {
          link = GetI18n(element, "url", languageID);
        }

        newData.push({
          subTitle,
          getWordKey: cardSettings?.getWordKey,
          imageUrl: imagesReplace(imagePath, "size1") || null,
          summary: GetI18n(element, "summary", languageID) || null,
          pageTitle: GetI18n(element, "pageTitle", languageID) || null,
          desc: GetI18n(element, "description", languageID) || null,
          shortDesc: GetI18n(element, "shortDescription", languageID) || null,
          url,
          link,
          id,
        });
      });
    }
  }
  return newData;
}
