import axios from "axios";
import { getLocalstorage } from "./localStore/localStorage";

//local languageıd setliyor
export const getLanguageID = () => {
  if (typeof window !== "undefined") {
    return getLocalstorage("languageID");
  }
};

//server da cookie setliyor
export const setApiServerCookie = async (key, value) => {
  await axios({
    method: "post",
    url: "/api/httpOnly",
    data: { key, value },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

